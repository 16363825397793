'use client';

import { useMemo } from 'react';
import ProgressBar from '@ramonak/react-progress-bar';

import { _ } from 'common';
import { Button } from 'components';

interface IFileProgress {
    percentage: number;
    name: string;
    index: number;
}

interface IProps {
    title: string;
    buttonText: string;
    progress: {
        [key: number]: IFileProgress;
    };
    handleClose: () => void;
}

const FileUploadProgress = ({ title, buttonText, progress, handleClose }: IProps) => {
    const files = useMemo(() => {
        return progress ? _.values(progress) : [];
    }, [progress]);

    const buttonDisabled = files.filter((item: IFileProgress) => item.percentage < 100).length > 0;

    return (
        <div>
            <p className="text-title mb-24 text-center">{title}</p>
            {files.map((item: IFileProgress, index: number) => (
                <div key={`file-upload-progress-${index}`} className="mb-16">
                    <p>{item.name}</p>
                    <ProgressBar completed={item.percentage} bgColor="#64B054" />
                </div>
            ))}
            <div className="flex justify-center pt-32">
                <Button className="min-w-[150px]" onClick={handleClose} disabled={buttonDisabled}>
                    {buttonText}
                </Button>
            </div>
        </div>
    );
};

export default FileUploadProgress;
