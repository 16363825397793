'use client';

import { useMemo } from 'react';

import { helpers } from 'common';
import { Icon, Label } from 'components';

import styles from 'components/checkbox/styles/checkbox.module.scss';

interface IProps {
    name: string;
    value?: boolean;
    onChange?: (_e: React.FormEvent<HTMLInputElement>) => void;
    label?: string;
    labelComponent?: JSX.Element;
    disabled?: boolean;
    className?: string;
    id?: string;
    wrapperClassName?: string;
    labelClassName?: string;
}

const { cn, generateRandomString } = helpers;

const Checkbox = ({ name, onChange, label, labelComponent, value, id, className, disabled, wrapperClassName, labelClassName, ...rest }: IProps) => {
    const inputId = useMemo(() => {
        return id || generateRandomString(12);
    }, [id]);

    return (
        <div className="flex items-center">
            <label htmlFor={inputId}>
                <div className={cn('flex items-center justify-center border mr-[10px] w-[24px] h-[24px] cursor-pointer rounded-[3px]', wrapperClassName || '!border-grey-400')}>
                    {value && <Icon name="check" />}
                    <input
                        name={name}
                        onChange={onChange}
                        type="checkbox"
                        checked={value}
                        disabled={disabled}
                        id={inputId}
                        className={cn('w-[24px] h-[24px] cursor-pointer', styles.checkbox, className)}
                        {...rest}
                    />
                </div>
            </label>
            {label && (
                <Label htmlFor={inputId} className={labelClassName || ''}>
                    {label}
                </Label>
            )}
            {labelComponent ? labelComponent : null}
        </div>
    );
};

export default Checkbox;
