'use client';

import { helpers } from 'common';

interface IProps {
    className?: string;
    children: React.ReactNode;
}

const { cn } = helpers;

const BottomToolbar = ({ className, children }: IProps) => {
    return <div className={cn('fixed bottom-0 left-0 w-full p-12 md:p-16 flex bg-grey-700 justify-center z-10', className)}>{children}</div>;
};

export default BottomToolbar;
