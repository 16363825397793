'use client';

import { useMemo, useState, useEffect } from 'react';
import { useRouter, usePathname, useSearchParams } from 'next/navigation';
import { useQuery } from '@tanstack/react-query';
import { useHydrated } from 'react-hydration-provider';
import Link from 'next/link';

import { MessagingProvider } from 'app/[locale]/messages/context/messaging-context';
import DesktopHeader from 'components/header/components/desktop-header';
import MobileHeader from 'components/header/components/mobile-header';

import { IUser, IInfoModalData } from 'common/types';
import { constants, routesConstants, queryKeys, redirectTo, _ } from 'common';
import { IRoute } from 'common/types';
import { removeSession, getSession, setUserDetails } from 'core';
import { authAPI, userAPI } from 'api';
import { Button, InfoModal } from 'components';
import { useTranslation } from 'i18n/client';

interface IProps {
    lang: string;
    userDetails?: IUser;
    token?: string;
    isUserLoggedIn?: boolean;
}

const { logoutAPI } = authAPI;
const { getUserDetailsAPI } = userAPI;
const { routes, prefixes } = routesConstants;

const Header = ({ lang, token, isUserLoggedIn }: IProps) => {
    const hydrated = useHydrated();
    const [modalData, setModalData] = useState<IInfoModalData>({ isOpen: false, title: '', message: '' });
    const [notLoggedInModalData, setNotLoggedInModalData] = useState<IInfoModalData>({ isOpen: false, title: '', message: '' });
    const { t } = useTranslation(lang, 'header');
    const router = useRouter();
    const pathname = usePathname();
    const searchParams = useSearchParams();
    const category = searchParams.get('slug');
    const session = getSession();
    const userDetails = session?.userDetails;

    const { data: fetchedUserDetails, refetch: refetchUserDetails } = useQuery({
        queryKey: [queryKeys.USER_DETAILS],
        queryFn: () => getUserDetailsAPI(),
        enabled: false,
    });

    const isProductPage = pathname.indexOf(routes[prefixes.createProduct].path) > -1;

    const shouldRenderHeader = useMemo(() => {
        let appRoutes: any = Object.assign({}, routes);
        delete appRoutes[prefixes.home];
        appRoutes = _.values(appRoutes);

        const selectedRoute = pathname === `/${lang}` ? routes[prefixes.home] : appRoutes.find((item: IRoute) => pathname.indexOf(item.path) > -1);

        return selectedRoute?.prefix === prefixes.home || (selectedRoute?.show_header && !selectedRoute?.hide_header) || (selectedRoute?.is_protected && !selectedRoute?.hide_header && token);
    }, [pathname, token, lang]);

    const handleLogout = async () => {
        await logoutAPI(token || '');
        removeSession();
        router.push(`${routes[prefixes.login].path}`);
        router.refresh();
    };

    const handleGoToCreateProductPage = async () => {
        try {
            let path = session?.accessToken ? `/${lang}${routes[prefixes.createProduct].path}?step=category` : `/${lang}${routes[prefixes.login].path}`;

            if (session?.accessToken) {
                const response = await getUserDetailsAPI();
                const details = response?.data || {};

                if (details.verificationStatus === constants.USER_STATUSES.initial.value) {
                    const goToKyc = () => {
                        path = `/${lang}${routes[prefixes.kyc].path}/${session?.userDetails?.uuid || ''}`;
                        router.push(path);
                    };

                    setModalData({
                        isOpen: true,
                        title: t('START_VERIFICATION_TITLE'),
                        message: t('START_VERIFICATION_MESSAGE'),
                        buttonText: t('START_VERIFICATION_BUTTON'),
                        handleButtonClick: goToKyc,
                    });
                    return;
                }

                if (details.verificationStatus === constants.USER_STATUSES.pending.value) {
                    setModalData({ isOpen: true, title: t('PENDING_VERIFICATION_TITLE'), message: t('PENDING_VERIFICATION_MESSAGE') });
                    return;
                }

                if (details.verificationStatus === constants.USER_STATUSES.declined.value) {
                    setModalData({
                        isOpen: true,
                        title: t('DECLINED_VERIFICATION_TITLE'),
                        message: t('DECLINED_VERIFICATION_MESSAGE'),
                        buttonText: t('DECLINED_VERIFICATION_BUTTON'),
                        handleButtonClick: () => {
                            router.push(`/${lang}${routes[prefixes.kyc].path}/${session?.userDetails?.uuid || ''}`);
                        },
                    });
                    return;
                }

                router.push(path);
            } else {
                router.push(path);
            }
        } catch (error) {
            console.log('Error redirecting to the create product page.');
        }
    };

    const getTosUrl = async (): Promise<string> => {
        return routes[prefixes.terms].path;
    };

    const handleGoToBanners = async () => {
        try {
            if (isUserLoggedIn) {
                const response = await getUserDetailsAPI();
                const details = response?.data || {};
                const path = `/${lang}${routes[prefixes.kyc].path}/${userDetails?.uuid || ''}`;

                if (details.verificationStatus === constants.USER_STATUSES.initial.value) {
                    const goToKyc = () => {
                        redirectTo(path);
                    };

                    setModalData({
                        isOpen: true,
                        title: t('START_VERIFICATION_TITLE'),
                        message: t('START_VERIFICATION_MESSAGE'),
                        buttonText: t('START_VERIFICATION_BUTTON'),
                        handleButtonClick: goToKyc,
                    });
                    return;
                }

                if (details.verificationStatus === constants.USER_STATUSES.pending.value) {
                    setModalData({ isOpen: true, title: t('PENDING_VERIFICATION_TITLE'), message: t('PENDING_VERIFICATION_MESSAGE') });
                    return;
                }

                if (details.verificationStatus === constants.USER_STATUSES.declined.value) {
                    setModalData({
                        isOpen: true,
                        title: t('DECLINED_VERIFICATION_TITLE'),
                        message: t('DECLINED_VERIFICATION_MESSAGE'),
                        buttonText: t('DECLINED_VERIFICATION_BUTTON'),
                        handleButtonClick: () => {
                            redirectTo(path);
                        },
                    });
                    return;
                }

                redirectTo(`/${lang}${routes[prefixes.banners].path}`);
            } else {
                redirectTo(`/${lang}${routes[prefixes.login].path}`);
            }
        } catch (error) {
            console.log('Error redirecting to the banners page.');
        }
    };

    const handleSetNotLoggedInModalData = (title: string, message: string) => {
        setNotLoggedInModalData({
            isOpen: true,
            title,
            message,
        });
    };

    useEffect(() => {
        if (token) {
            refetchUserDetails();
        }
    }, [refetchUserDetails, token]);

    useEffect(() => {
        if (fetchedUserDetails?.data) {
            setUserDetails(fetchedUserDetails.data);
        }
    }, [fetchedUserDetails]);

    if (!shouldRenderHeader || !hydrated) {
        return null;
    }

    return (
        <MessagingProvider>
            <div className="sticky top-0 w-full z-[99] bg-white-main h-fit">
                <InfoModal {...modalData} handleClose={() => setModalData({ isOpen: false, title: '', message: '' })} />
                <InfoModal
                    isOpen={notLoggedInModalData.isOpen}
                    title=""
                    message=""
                    modalClassName="!p-[0px] !max-w-[350px]"
                    wrapperClassName="!px-24 !py-[0px]"
                    handleClose={() => setNotLoggedInModalData({ isOpen: false, title: '', message: '' })}
                    customContent={
                        <div>
                            <p className="text-[20px] text-black-100 font-semibold mb-24">{t(notLoggedInModalData.title)}</p>
                            <p className="text-sm-title text-grey-300 mb-12">{t(notLoggedInModalData.message)}</p>
                            <Link href={`/${lang}${routes[prefixes.registration].path}`} onClick={() => setNotLoggedInModalData({ isOpen: false, title: '', message: '' })}>
                                <Button variant="secondary" className="w-full my-16">
                                    {t('REGISTER_BTN')}
                                </Button>
                            </Link>
                            <Link href={`/${lang}${routes[prefixes.login].path}`} onClick={() => setNotLoggedInModalData({ isOpen: false, title: '', message: '' })}>
                                <Button className="w-full mb-16">{t('LOGIN_BTN')}</Button>
                            </Link>
                        </div>
                    }
                    hideButton
                />
                <div className="hidden lg:block">
                    <DesktopHeader
                        lang={lang}
                        userDetails={userDetails}
                        handleLogout={handleLogout}
                        handleGoToCreateProductPage={handleGoToCreateProductPage}
                        token={token}
                        isProductPage={isProductPage}
                        pathname={pathname}
                        category={category || ''}
                        isUserLoggedIn={isUserLoggedIn}
                        getTosUrl={getTosUrl}
                        handleSetNotLoggedInModalData={handleSetNotLoggedInModalData}
                        handleGoToBanners={handleGoToBanners}
                    />
                </div>
                <div className="block lg:hidden">
                    <MobileHeader
                        lang={lang}
                        userDetails={userDetails}
                        handleLogout={handleLogout}
                        handleGoToCreateProductPage={handleGoToCreateProductPage}
                        token={token}
                        isProductPage={isProductPage}
                        pathname={pathname}
                        category={category || ''}
                        isUserLoggedIn={isUserLoggedIn}
                        getTosUrl={getTosUrl}
                        handleSetNotLoggedInModalData={handleSetNotLoggedInModalData}
                        handleGoToBanners={handleGoToBanners}
                    />
                </div>
            </div>
        </MessagingProvider>
    );
};

export default Header;
