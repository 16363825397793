'use client';

import Link from 'next/link';

import { Icon } from 'components';

interface IProps {
    path?: string;
    onClick?: () => void;
    children: string | React.ReactNode;
}

const ButtonContent = ({ content }: { content: string | React.ReactNode }) => (
    <div className="flex items-center">
        <Icon name="arrow-left" className="mr-8" />
        {typeof content === 'string' ? <p className="text-sm-title-light text-grey-300">{content}</p> : content}
    </div>
);

const BackButton = ({ path, onClick, children }: IProps) => {
    if (!path && !onClick) {
        return null;
    }

    if (path) {
        return (
            <Link href={path}>
                <ButtonContent content={children} />
            </Link>
        );
    }

    return (
        <div className="cursor-pointer" onClick={onClick}>
            <ButtonContent content={children} />
        </div>
    );
};

export default BackButton;
