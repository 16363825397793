import { routesConstants, _ } from 'common';
import { IRoute } from 'common/types';

export const isSelectedMenuItem = (path?: string, pathname?: string, category?: string, query?: string, skipSelected?: boolean) => {
    if (!path || !pathname || skipSelected) {
        return null;
    }

    if (query) {
        return category === query;
    } else {
        return pathname.indexOf(path) > -1;
    }
};

export const isSearchHidden = (pathname: string) => {
    const { routes } = routesConstants;
    const allRoutes = _.values(routes);
    const selectedRoute: any = allRoutes.find((route: IRoute) => pathname.indexOf(route.path) > -1 && route.path !== '/') || null;

    return selectedRoute?.hide_search_bar || false;
};
