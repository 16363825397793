'use client';
import { useState } from 'react';
import { NumericFormat } from 'react-number-format';

import { Icon, Label } from 'components';
import { helpers } from 'common';
import { Icons } from 'components/icon/types';

interface NumberFormatValues {
    floatValue: number | undefined;
    formattedValue: string;
    value: string;
}

interface IProps {
    name: string;
    value?: string | number;
    onChange?: (_e: React.FormEvent<HTMLInputElement>) => void;
    onBlur?: (_e: React.FormEvent<HTMLInputElement>) => void;
    onKeyDown?: (_e: React.FormEvent<HTMLInputElement>) => void;
    type?: 'text' | 'email' | 'password' | 'number';
    error?: string;
    label?: string;
    placeholder?: string;
    touched?: boolean;
    disabled?: boolean;
    readOnly?: boolean;
    wrapperProps?: object;
    className?: string;
    id?: string;
    showPasswordSwitch?: boolean;
    wrapperClassName?: string;
    required?: boolean;
    icon?: string;
    autoFocus?: boolean;
    iconClassName?: string;
    withDropdown?: boolean;
    autoComplete?: 'on' | 'off' | string;
    customDropdown?: React.ReactNode;
    onlyPositiveNumbers?: boolean;
    inputWrapperClassName?: string;
    pattern?: string;
    infoText?: string;
    onlyNumbers?: boolean;
    iconWrapperClassName?: string;
    iconRight?: React.ReactNode;
    showPriceInput?: boolean;
    handleManualChange?: (_name: string, _value?: string | number) => void;
}

const { cn } = helpers;

const Input = ({
    name,
    value,
    onChange,
    onBlur,
    onKeyDown,
    type = 'text',
    error,
    label,
    placeholder,
    touched,
    disabled,
    readOnly,
    wrapperProps,
    className = '',
    id,
    showPasswordSwitch,
    wrapperClassName,
    required,
    icon,
    autoFocus,
    iconClassName,
    withDropdown,
    customDropdown,
    autoComplete,
    onlyPositiveNumbers,
    inputWrapperClassName,
    pattern,
    infoText,
    onlyNumbers,
    iconWrapperClassName,
    iconRight,
    showPriceInput,
    handleManualChange,
    ...rest
}: IProps) => {
    const [showPassword, setShowPassword] = useState<boolean>(false);

    const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
        if (onlyPositiveNumbers && type === 'number' && e.currentTarget?.value && parseInt(e.currentTarget?.value, 10) < 0) {
            return;
        }

        if (onlyNumbers && e.currentTarget.value) {
            if (e.currentTarget.value.indexOf(',') > -1 || !/^-?\d*\.?\d+$|^\d+\.?\d*$/.test(e.currentTarget.value)) {
                return;
            }

            const inputValue = e.currentTarget.value.replace(/[^0-9]/g, '');

            if (!inputValue) {
                return;
            }
        }

        if (onChange) {
            onChange(e);
        }
    };

    const handleBlur = (e: React.FormEvent<HTMLInputElement>) => {
        if (onlyPositiveNumbers && type === 'number' && e.currentTarget?.value && parseInt(e.currentTarget?.value, 10) < 0) {
            return;
        }

        if (onlyNumbers && e.currentTarget.value) {
            if (e.currentTarget.value.indexOf(',') > -1 || !/^-?\d*\.?\d+$|^\d+\.?\d*$/.test(e.currentTarget.value)) {
                return;
            }

            const inputValue = e.currentTarget.value.replace(/[^0-9]/g, '');

            if (!inputValue) {
                return;
            }
        }

        if (onBlur) {
            onBlur(e);
        }
    };

    const handleNumberTypeChange = (values?: NumberFormatValues) => {
        if (handleManualChange) {
            handleManualChange(name, values?.floatValue || 0);
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (onKeyDown) {
            onKeyDown(e);
            return;
        }
        if (type !== 'number') return;
        // Allow only digits, backspace, and delete
        if (!/^\d$/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete') {
            e.preventDefault();
        }
    };

    return (
        <div className={cn('pb-32', wrapperClassName || '')} {...(wrapperProps || {})}>
            {label && (
                <div className={error && touched ? 'text-red-100' : 'text-grey-100'}>
                    <Label htmlFor={id || name}>{label}</Label>
                    {required && <span className="ml-2 font-light text-red-200">*</span>}
                </div>
            )}
            <div className={cn('block relative', inputWrapperClassName || '')}>
                {icon && (
                    <div className={cn('absolute left-[15px] top-[24px]', iconWrapperClassName || '')}>
                        <Icon name={icon as Icons} className={iconClassName || ''} strokeColor fillColor />
                    </div>
                )}
                {showPriceInput && (
                    <NumericFormat
                        value={value ? value.toString() : ''}
                        allowLeadingZeros
                        decimalSeparator=","
                        decimalScale={2}
                        onValueChange={handleNumberTypeChange}
                        className={cn(
                            'text-input border-b border-b-grey-200 w-full my-8 py-16 outline-none text-black-100',
                            error && touched ? 'border-b-red-100' : 'border-b-grey-200',
                            icon ? 'pl-48' : '',
                            className,
                            infoText || iconRight ? 'pr-48' : '',
                        )}
                    />
                )}
                {!showPriceInput && (
                    <input
                        name={name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onKeyDown={handleKeyDown}
                        type={showPassword ? 'text' : type}
                        value={value || ''}
                        disabled={disabled}
                        readOnly={readOnly}
                        placeholder={placeholder}
                        autoFocus={autoFocus || false}
                        id={id || name}
                        pattern={pattern}
                        className={cn(
                            'text-input border-b border-b-grey-200 w-full my-8 py-16 outline-none text-black-100',
                            error && touched ? 'border-b-red-100' : 'border-b-grey-200',
                            icon ? 'pl-48' : '',
                            className,
                            infoText || iconRight ? 'pr-48' : '',
                        )}
                        autoComplete={autoComplete}
                        onWheel={(e: any) => e?.target?.blur()}
                        {...rest}
                    />
                )}
                {type === 'password' && showPasswordSwitch && (
                    <div className="absolute right-0 top-[24px] cursor-pointer">
                        {showPassword ? (
                            <Icon style={{ cursor: 'pointer' }} name="eye" onClick={() => setShowPassword(false)} />
                        ) : (
                            <Icon style={{ cursor: 'pointer' }} name="eye-off" onClick={() => setShowPassword(true)} />
                        )}
                    </div>
                )}
                {iconRight ? null : infoText ? (
                    <div className="absolute right-[8px] top-[26px]">
                        <p className="text-sm-title text-grey-500">{infoText}</p>
                    </div>
                ) : null}
                {iconRight && !infoText && <div className="absolute right-[8px] top-[26px]">{iconRight}</div>}
            </div>
            {error && touched && (
                <div className="relative">
                    <p className="text-error text-red-100 absolute top-[-4px]">{error}</p>
                </div>
            )}
            {withDropdown && customDropdown ? customDropdown : null}
        </div>
    );
};

export default Input;
