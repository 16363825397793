'use client';

import Image from 'next/image';

import { IArticle } from 'components/onboarding-article/types';
import { useTranslation } from 'i18n/client';

interface IProps {
    article: IArticle;
    lang: string;
    ns?: string;
}

const OnboardingArticle = ({ article, lang, ns }: IProps) => {
    const { t } = useTranslation(lang, ns);

    return (
        <div className="w-full h-full relative">
            <Image src={article.image_url} alt="onboarding-article-img" fill objectFit="cover" className="!relative rounded-[24px]" />
            {article?.title && (
                <p
                    className="absolute inset-0 text-center left-0 right-0 flex justify-center items-center text-white-main text-xxl-title"
                    style={article?.details?.name ? { transform: 'translateY(-50%)', top: '50%' } : { bottom: '35px' }}
                >
                    {t(article.title)}
                </p>
            )}
        </div>
    );
};

export default OnboardingArticle;
