import Link from 'next/link';

import UserMenuItem from 'components/header/components/user-menu-item';
import { useMessaging } from 'app/[locale]/messages/context/messaging-context';
import { useTranslation } from 'i18n/client';
import { IUser } from 'common/types';
import { Button, Icon, Logo } from 'components';
import { MENU_ITEMS } from 'components/header/constants';
import { IMenuItem } from 'components/header/types';
import { Icons } from 'components/icon/types';
import { helpers } from 'common';
import { isSelectedMenuItem } from 'components/header/helpers/header-helpers';
import { routesConstants } from 'common';

interface IProps {
    lang: string;
    userDetails?: IUser;
    handleLogout: () => void;
    handleGoToCreateProductPage: () => void;
    token?: string;
    isProductPage?: boolean;
    pathname: string;
    category?: string;
    isUserLoggedIn?: boolean;
    getTosUrl: () => Promise<string>;
    handleSetNotLoggedInModalData: (_title: string, _message: string) => void;
    handleGoToBanners: () => void;
}

const { cn } = helpers;
const { routes, prefixes } = routesConstants;

const MobileHeader = ({
    lang,
    userDetails,
    handleLogout,
    handleGoToCreateProductPage,
    isProductPage,
    pathname,
    category,
    isUserLoggedIn,
    getTosUrl,
    handleSetNotLoggedInModalData,
    handleGoToBanners,
}: IProps) => {
    const { t } = useTranslation(lang, 'header');
    const { underMessagesCount } = useMessaging();

    const handleOpenRules = async () => {
        const path = await getTosUrl();

        const newTab = window.open(path, '_blank');

        if (newTab) {
            newTab.focus();
        }
    };

    return (
        <div className="p-12">
            <div className="flex items-center">
                <UserMenuItem
                    lang={lang}
                    userDetails={userDetails}
                    handleLogout={handleLogout}
                    triggerItem={<Icon name="hamburger" className="mr-12" />}
                    handleOpenRules={handleOpenRules}
                    isMobile
                    isUserLoggedIn={isUserLoggedIn}
                    handleSetNotLoggedInModalData={handleSetNotLoggedInModalData}
                    handleGoToBanners={handleGoToBanners}
                />
                <div className="mt-[3px]">
                    <Logo lang={lang} />
                </div>
                <div className="flex items-center ml-[auto]">
                    <>
                        {!isProductPage && isUserLoggedIn && (
                            <Button variant="secondary" className="rounded-[50%] p-[6px] mr-32" onClick={handleGoToCreateProductPage}>
                                <Icon name="plus" className="w-[24px] h-[24px]" />
                            </Button>
                        )}
                        {isUserLoggedIn && (
                            <Link href={`/${lang}${routes[prefixes.messages].path}`}>
                                <div className="relative">
                                    <Icon name="message" className="mr-12" />
                                    {underMessagesCount ? (
                                        <div
                                            className={cn(
                                                'absolute rounded-[50%] w-[16px] h-[16px] bg-red-200 text-white-main text-[12px] flex items-center justify-center',
                                                underMessagesCount > 100 ? 'w-[20px] h-[20px] bottom-[-7px] right-[1px]' : 'w-[16px] h-[16px] bottom-0 right-[8px]',
                                            )}
                                        >
                                            {underMessagesCount}
                                        </div>
                                    ) : null}
                                </div>
                            </Link>
                        )}
                        {!isUserLoggedIn && (
                            <Icon name="message" className="mr-12 cursor-pointer" onClick={() => handleSetNotLoggedInModalData('NOT_LOGGED_IN_MESSAGES_TITLE', 'NOT_LOGGED_IN_MESSAGES_SUBTITLE')} />
                        )}
                    </>
                </div>
            </div>
            <div className="grid grid-cols-4 gap-12 m-[auto] mt-32 overflow-y-auto">
                {MENU_ITEMS.map((item: IMenuItem, index: number) => {
                    const isSelected = isSelectedMenuItem(item.path, pathname, category, item.query, item.skipSelected);
                    const path = `/${lang}${item.path}${item.query ? `?slug=${item.query}` : ''}`;

                    if (item.path) {
                        return (
                            <div key={`menu-item-${index}`}>
                                <Link href={path} className="text-sm-title-light font-normal text-grey-300">
                                    <div className="flex flex-col justify-center">
                                        {item.icon && <Icon name={item.icon as Icons} className={cn('m-[auto]', isSelected ? 'green-icon' : '')} />}
                                        <p className={cn('m-[auto]', isSelected ? 'text-green-100 text-[12px] font-medium' : 'text-[12px] text-grey-300 font-light')}>{t(item.label)}</p>
                                    </div>
                                </Link>
                            </div>
                        );
                    }

                    return null;
                })}
            </div>
        </div>
    );
};

export default MobileHeader;
