'use client';

import { useEffect, useMemo } from 'react';
import { usePathname, useSearchParams } from 'next/navigation';
import { routesConstants, _ } from 'common';
import { IRoute } from 'common/types';

const { routes, prefixes } = routesConstants;

const ScrollToTop = () => {
    const pathname = usePathname();
    const searchParams = useSearchParams();

    const appRoutes: IRoute[] = useMemo(() => {
        const allRoutes: any = Object.assign({}, routes);
        delete allRoutes[prefixes.home];
        return _.values(allRoutes);
    }, []);

    useEffect(() => {
        if (document.body.classList.contains('modal-open')) {
            document.body.classList.remove('modal-open');
        }
    }, [pathname]);

    useEffect(() => {
        const selectedRoute: IRoute | undefined = appRoutes.find((item: IRoute) => pathname.indexOf(item.path) > -1);

        if (selectedRoute?.prevent_scroll_top) {
            return;
        }

        window.scrollTo(0, 0);
    }, [pathname, searchParams, appRoutes]);
    return null;
};

export default ScrollToTop;
