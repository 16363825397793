'use client';

import Image from 'next/image';

import { helpers } from 'common';
import EmptyStateImage from 'components/empty-state/assets/empty-state.svg';

interface IProps {
    className?: string;
    icon?: React.ReactNode;
    children: React.ReactNode | string;
}

const { cn } = helpers;

const EmptyState = ({ className, icon, children }: IProps) => (
    <div className={cn('w-full bg-grey-1000 p-12 rounded-[9px]', className || '')}>
        {icon || <Image src={EmptyStateImage} alt="empty-state-image" width={48} height={48} />}
        <div className="mt-12">{typeof children === 'string' ? <p className="text-black-100 text-[18px] font-light leading-[28px]">{children}</p> : children}</div>
    </div>
);

export default EmptyState;
