'use client';

import { helpers } from 'common';

interface IProps {
    children: string | React.ReactNode;
    htmlFor?: string;
    className?: string;
}

const { cn } = helpers;

const Label = ({ children, htmlFor, className, ...rest }: IProps) => (
    <label htmlFor={htmlFor} className={cn('text-grey-100 text-label font-light', className || '')} {...rest}>
        {children}
    </label>
);

export default Label;
