'use client';

import { useState, useEffect, useCallback } from 'react';

import { helpers } from 'common';
import { sharedAPI } from 'api';

interface IProps {
    url: string;
    index?: number;
    starred?: boolean;
    onClick?: (_index?: number) => void;
    isAvailable?: boolean;
    children: React.ReactNode;
}

const { cn } = helpers;
const { isImageAvailable } = sharedAPI;
const MAX_REPEAT = 5;

const BackgroundImage = ({ url, index, starred, onClick, isAvailable, children }: IProps) => {
    const [imageAvailable, setImageAvailable] = useState<boolean>(false);
    const [counter, setCounter] = useState<number>(0);

    const getImageAvailability = async (currentCounter: number) => {
        const available = isAvailable || (await isImageAvailable(url));

        setImageAvailable(available);

        if (!available && currentCounter < MAX_REPEAT) {
            setTimeout(() => {
                setCounter(currentCounter + 1);
            }, 2000);
        }
    };

    const memoizeCallback = useCallback(getImageAvailability, [url, isAvailable]);

    useEffect(() => {
        memoizeCallback(counter);
    }, [counter, memoizeCallback]);

    if (!imageAvailable) {
        return null;
    }

    return (
        <div
            className={cn('relative w-full cursor-pointer h-[250px] rounded-[13px] bg-cover bg-center', starred ? 'border-[3px] border-yellow-100' : '')}
            style={{ backgroundImage: `url(${url})` }}
            onClick={() => {
                if (onClick) {
                    onClick(index);
                }
            }}
        >
            {children}
        </div>
    );
};

export default BackgroundImage;
