'use client';

import { useState } from 'react';
import { toast } from 'react-hot-toast';

import { constants } from 'common';
import { config } from 'core';
import { sharedAPI } from 'api';
import { Button, Icon, Input, Upload } from 'components';

interface IProps {
    sendButton?: string;
    placeholder?: string;
    maxFileSizeError?: string;
    maxImagesError?: string;
    uploadInProgressText?: string;
    uploadErrorText?: string;
    onSend: (_value: string, _onSuccess: () => void, _image?: string) => void;
}

const { getUploadUrl, uploadToAws } = sharedAPI;

const ChatFooter = ({ sendButton, placeholder, maxFileSizeError, maxImagesError, uploadErrorText, onSend }: IProps) => {
    const [value, setValue] = useState('');

    const onSuccessSendMessage = () => {
        setValue('');

        const element = document.getElementById('chat-messages-container');

        if (element) {
            element.scrollTop = element.scrollHeight;
        }
    };

    const handleSendMessage = () => {
        if (!value) {
            return;
        }

        onSend(value, onSuccessSendMessage);
    };

    const handleKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            handleSendMessage();
        }
    };

    const uploadImages = async (files: FileList) => {
        try {
            const uploaded: string[] = [];

            if (files && files.length > 0) {
                Array.prototype.forEach.call(files, async (file: File, index: number) => {
                    if (file) {
                        const res = await getUploadUrl({ kind: config.IMAGES_BUCKET, fileName: file.name, contentType: file.type });
                        const url = res.accessURL;
                        const uploadUrl = res.url;
                        uploaded.push(url);

                        await uploadToAws(file, uploadUrl, index);

                        onSend(url, onSuccessSendMessage, url);
                    }
                });
            }
        } catch (error) {
            toast.error(uploadErrorText || '');
        }
    };

    const handleUploadFiles = (files: FileList) => {
        if (files.length > 3) {
            toast.error(maxImagesError || '');
            return;
        }

        for (let i = 0; i < files.length; i++) {
            if (files[i].size > constants.MAX_UPLOAD_SIZE.otherImages) {
                toast.error(maxFileSizeError || '');
                return;
            }
        }

        uploadImages(files);
    };

    return (
        <div className="relative">
            <Input
                name="message"
                value={value}
                onChange={(e) => setValue(e.currentTarget.value || '')}
                onKeyDown={handleKeyDown}
                placeholder={placeholder}
                className="pl-8 pr-[105px] md:pr-[250px] border border-grey-800 rounded-[65px]"
                wrapperClassName="pb-0"
            />
            <div className="absolute top-[18px] right-[8px] flex items-center">
                <Upload id="upload-chat-images" acceptMultiple onChange={handleUploadFiles} accept="image/png, image/jpeg" isCustomButton>
                    <div className="bg-grey-800 p-8 rounded-[50%] flex items-center justify-center w-fit cursor-pointer">
                        <Icon name="file-image" />
                    </div>
                </Upload>
                {sendButton && (
                    <>
                        <Button className="ml-8 h-[38px] !rounded-[26px] hidden md:flex" disabled={!value} showDisabled onClick={handleSendMessage}>
                            {sendButton}
                            <Icon name="send" className="ml-8 white-icon" />
                        </Button>
                        <Button className="ml-8 h-[40px] w-[40px] p-[8px] !rounded-[50%] flex items-center justify-center md:hidden" disabled={!value} showDisabled onClick={handleSendMessage}>
                            <Icon name="send" className="white-icon" />
                        </Button>
                    </>
                )}
            </div>
        </div>
    );
};

export default ChatFooter;
