'use client';

import { useMemo } from 'react';
import { useRouter } from 'next/navigation';

import { helpers } from 'common';

interface IStep {
    title: string;
    value: string;
}

interface IProps {
    title?: string;
    subtitle?: string;
    steps: IStep[];
    step: string;
    wrapperClassName?: string;
    lang?: string;
    path?: string;
    params?: {
        [key: string]: string | number | boolean;
    };
    onChange?: (_step: string) => void;
    preventSwitch?: boolean;
    isFirstStepDisabled?: boolean;
}

const { cn } = helpers;

const StepsWizzard = ({ title, subtitle, steps, step, wrapperClassName, lang, path, params, onChange, preventSwitch, isFirstStepDisabled }: IProps) => {
    const router = useRouter();

    const urlParams = (params?: { [key: string]: string | number | boolean }) => {
        let result = '';

        if (params) {
            Object.keys(params).forEach((key) => {
                const value = params[key as keyof typeof params];

                if (value) {
                    result += `&${key}=${value}`;
                }
            });
        }

        return result;
    };

    const renderCurrentStep = useMemo(() => {
        const currentStep = steps.find((item: IStep) => item.value === step);

        if (currentStep) {
            return (
                <div className="flex items-center p-16 mr-16 border-b-[3px] border-green-100">
                    <span className="text-sm-title font-semibold text-green-100 whitespace-nowrap ml-4">{currentStep.title}</span>
                </div>
            );
        }

        return null;
    }, [step, steps]);

    const renderSteps = useMemo(() => {
        const currentIndex = steps.findIndex((item: IStep) => item.value === step);

        const handleChange = (tab: string, index: number) => {
            if (preventSwitch || (isFirstStepDisabled && !index)) {
                return;
            }

            if (currentIndex <= index) {
                return;
            }

            if (lang && path) {
                router.replace(`/${lang}${path}?step=${tab}${urlParams(params)}`);
            } else {
                if (typeof onChange === 'function') {
                    onChange(tab);
                }
            }
        };

        return steps.map((item: IStep, index) => {
            if (item.title) {
                return (
                    <div
                        key={`wizzard-step-${item.value}`}
                        className={cn(
                            'flex items-center p-16 mr-16',
                            item.value === step ? 'border-b-[3px] border-green-100' : '',
                            preventSwitch || currentIndex <= index || (isFirstStepDisabled && !index) ? 'pointer-events-none' : 'cursor-pointer',
                        )}
                        onClick={() => handleChange(item.value, index)}
                    >
                        <span className={item.value === step ? 'text-sm-title font-semibold text-green-100' : 'text-sm-title text-grey-500'}>{`${index + 1}.`}</span>
                        <span className={item.value === step ? 'text-sm-title font-semibold text-green-100 whitespace-nowrap ml-4' : 'text-sm-title text-grey-500 whitespace-nowrap ml-4'}>
                            {item.title}
                        </span>
                    </div>
                );
            }

            return null;
        });
    }, [steps, step, onChange, lang, params, path, router, preventSwitch, isFirstStepDisabled]);

    return (
        <div className={cn('bg-green-200 w-full pt-24', wrapperClassName || '')}>
            <div className="container mx-[auto]">
                {title && <p className="text-l-title text-black-main ml-16 mb-8">{title}</p>}
                {subtitle && <p className="text-sm-title text-grey-300 ml-16">{subtitle}</p>}
                <div className="pt-24 overflow-x-auto w-full hidden md:flex">{renderSteps}</div>
                <div className="flex md:hidden justify-center items-center mt-16">{renderCurrentStep}</div>
            </div>
        </div>
    );
};

export default StepsWizzard;
