'use client';

import Image from 'next/image';
import Link from 'next/link';
import { routesConstants, helpers } from 'common';

import LogoImage from 'components/logo/assets/logo.svg';

interface IProps {
    width?: number;
    lang: string;
    imageClassName?: string;
}

const { routes, prefixes } = routesConstants;
const { cn } = helpers;

const Logo = ({ width = 90, lang, imageClassName }: IProps) => (
    <Link href={`/${lang}${routes[prefixes.home].path}`}>
        <Image src={LogoImage} alt="logo-image" className={cn('relative', imageClassName || '')} width={width} />
    </Link>
);

export default Logo;
