'use client';

import { Loader } from 'components';
import { helpers } from 'common';

import styles from 'components/button/styles/button.module.scss';

interface IProps {
    type?: 'button' | 'submit';
    variant?: 'success' | 'secondary' | 'secondary-outline' | 'danger' | 'success-outline' | 'transparent' | 'danger-outline';
    className?: string;
    disabled?: boolean;
    showLoader?: boolean;
    showDisabled?: boolean;
    onClick?: () => void;
    children?: string | React.ReactNode;
}

const { cn } = helpers;

const Button = ({ type = 'button', variant = 'success', className = '', disabled, showLoader, showDisabled, onClick, children }: IProps) => {
    const classes = cn(styles.btn, styles[variant], className);

    return (
        <button type={type} className={cn(classes, className, disabled && showDisabled ? '!bg-grey-800 !text-grey-500' : '')} disabled={disabled} onClick={onClick ? onClick : () => {}}>
            {showLoader ? <Loader size={24} /> : children}
        </button>
    );
};

export default Button;
