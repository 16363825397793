'use client';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useState } from 'react';
import { Toaster } from 'react-hot-toast';

import { ScrollToTop } from 'components';

const Providers = ({ children }: { children: React.ReactNode }) => {
    const [client] = useState(() => new QueryClient());

    return (
        <QueryClientProvider client={client}>
            <ScrollToTop />
            {children}
            <ReactQueryDevtools initialIsOpen={false} />
            <Toaster
                toastOptions={{
                    duration: 5000,
                    style: {
                        zIndex: 9999,
                    },
                }}
                containerStyle={{
                    zIndex: 9999,
                }}
            />
        </QueryClientProvider>
    );
};

export default Providers;
