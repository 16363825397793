import React from 'react';

interface IProps {
    show?: boolean;
    children: JSX.Element;
}

const RenderIf = ({ show, children }: IProps): JSX.Element => {
    if (show) {
        return children;
    }

    return <></>;
};

export default RenderIf;
