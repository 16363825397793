import React from 'react';
import { HydrationProvider } from 'react-hydration-provider';

import { Analytics, Header, Footer, Providers } from 'components';
import { IUser } from 'common/types';
import { config } from 'core';

import 'common/styles/globals.css';

export type IProps = {
    isUserLoggedIn?: boolean;
    lang: string;
    userDetails?: IUser;
    token?: string;
    children: React.ReactNode;
};

const MainLayout = ({ lang, userDetails, token, children, isUserLoggedIn }: IProps) => {
    return (
        <HydrationProvider>
            <Providers>
                <Header lang={lang} userDetails={userDetails} token={token} isUserLoggedIn={isUserLoggedIn} />
                <main className="flex flex-col flex-[1] overflow-scroll">{children}</main>
                <Footer lang={lang} />
                {config.GTM_ID && <Analytics />}
            </Providers>
        </HydrationProvider>
    );
};

export default MainLayout;
