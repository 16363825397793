'use client';

/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { createContext, useContext, useEffect, useState } from 'react';

import { getSession, config, firebase } from 'core';
import { collection, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import { IUserPublic } from 'api/user/types';

interface NotificationsContextType {
    hasUnreadMessages: boolean;
    setHasUnreadMessages: (_value: boolean) => void;
    chatRooms: any[];
    setChatRooms: (_value: any[]) => void;
    setRefetch: (_value: boolean) => void;
    refetch: boolean;
    user?: IUserPublic;
    underMessagesCount?: number;
}

const { authenticateWithFirebase, db, getCurrentUser } = firebase;

const MessagingContext = createContext<NotificationsContextType | undefined>(undefined);

export const useMessaging = () => {
    const context = useContext(MessagingContext);
    if (!context) {
        throw new Error('useCreateArticle must be used within an AuthProvider');
    }
    return context;
};

export const MessagingProvider: React.FC<any> = ({ children }) => {
    const [hasUnreadMessages, setHasUnreadMessages] = useState(false);
    const [underMessagesCount, setUnreadMessagesCount] = useState(0);
    const session = getSession();
    const authenticated = !!session?.accessToken;
    const [chatRooms, setChatRooms] = useState<any>([]);
    const [refetch, setRefetch] = useState(false);
    const [user, setUser] = useState();

    useEffect(() => {
        if (!authenticated || !session?.userDetails?.uuid) {
            return;
        }

        const fetchChats = async () => {
            await authenticateWithFirebase();

            const currentUser: any = await getCurrentUser();

            if (!currentUser) {
                return;
            }

            setUser(currentUser);

            const chatsRef = collection(db, `${config.FIREBASE_CHAT_DB_NAME}`);
            const q = query(chatsRef, where('participants', 'array-contains', session?.userDetails?.uuid), orderBy('updatedAt', 'desc'));

            let unsubscribe: any;

            try {
                unsubscribe = onSnapshot(q, (querySnapshot) => {
                    const chatRooms = querySnapshot.docs.map((doc) => {
                        const chatData = doc.data();
                        return {
                            id: doc.id,
                            createdAt: chatData.createdAt,
                            productUUID: chatData.productUUID,
                            productName: chatData.productName,
                            unreadCount: chatData.unreadCount,
                            sellerUUID: chatData.sellerUUID,
                            lastMessage: chatData.lastMessage,
                            avatarImageUrls: chatData.avatarImageUrls,
                            participants: chatData.participants,
                            usernames: chatData.usernames,
                        };
                    });
                    setChatRooms(chatRooms);
                    setHasUnreadMessages(chatRooms.filter((chatRoom) => chatRoom.unreadCount[session?.userDetails?.uuid || ''] > 0).length > 0);
                    setUnreadMessagesCount(chatRooms.filter((chatRoom) => chatRoom.unreadCount[session?.userDetails?.uuid || ''] > 0).length);
                });
            } catch (error) {
                console.log('Error fetching chat rooms: ', error);
            }

            return () => {
                if (unsubscribe) {
                    unsubscribe();
                }
            };
        };

        void fetchChats();
    }, [authenticated, session?.userDetails?.uuid, refetch]);

    return (
        <MessagingContext.Provider
            value={{
                setHasUnreadMessages,
                hasUnreadMessages,
                chatRooms,
                setChatRooms,
                setRefetch,
                refetch,
                user,
                underMessagesCount,
            }}
        >
            {children}
        </MessagingContext.Provider>
    );
};
