'use client';

import { toast } from 'react-hot-toast';

import { useTranslation } from 'i18n/client';
import { Button, Icon } from 'components';

interface IProps {
    lang: string;
    phoneNumber: string;
    handleCloseModal: () => void;
}

const UserPhoneNumber = ({ lang, phoneNumber, handleCloseModal }: IProps) => {
    const { t } = useTranslation(lang, 'common');

    const copyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(phoneNumber || '');
            toast.success(t('SUCCESS_COPY'));
            handleCloseModal();
        } catch (err) {
            console.error('Failed to copy: ', err);
        }
    };

    const openViberChat = () => {
        const formattedPhoneNumber = phoneNumber.replace(/\+/g, '%2B');
        const viberLink = `viber://chat?number=${formattedPhoneNumber}`;

        window.open(viberLink, '_blank');
        handleCloseModal();
    };

    return (
        <>
            <div className="max-w-[320px] mx-[auto]">
                <p className="mt-[-15px] text-[20px] text-grey-300 text-center font-medium">{t('PHONE_NUMBER_MODAL_TITLE')}</p>
                <div className="flex items-center justify-center">
                    <div className="mt-[58px] flex items-center mx-[auto]">
                        <p className="text-[40px] text-black-main font-medium">{phoneNumber}</p>
                    </div>
                </div>
            </div>
            <div className="flex flex-wrap items-center mt-32">
                <div className="w-full md:w-1/2 pr-[0px] md:pr-4">
                    <Button variant="transparent" className="w-full !bg-grey-800 !border-none !text-black-main !max-h-[50px] !text-[14px] !font-normal" onClick={copyToClipboard}>
                        <Icon name="copy" className="mr-[6px]" />
                        {t('COPY_PHONE_NUMBER_BUTTON')}
                    </Button>
                </div>
                <div className="w-full md:w-1/2 pl-[0px] md:pl-4 mt-8 md:mt-[0px]">
                    <Button variant="transparent" className="items-center flex w-full !border-none !bg-purple-100 !text-purple-200 !max-h-[50px] !text-[14px] !font-normal" onClick={openViberChat}>
                        <Icon name="viber" className="mr-[6px]" />
                        {t('OPEN_VIBER_BUTTON')}
                    </Button>
                </div>
            </div>
        </>
    );
};

export default UserPhoneNumber;
