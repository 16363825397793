'use client';

import { useMemo, useEffect, useState } from 'react';
import { format } from 'date-fns';
import Emoji from 'react-emoji-render';
import Image from 'next/image';
import Link from 'next/link';
import { Tooltip } from 'react-tooltip';
import { toast } from 'react-hot-toast';

import { IMessage } from 'app/[locale]/messages/types';
import { helpers } from 'common';
import { Icon, Modal } from 'components';

import styles from 'components/chat/styles/chat.module.scss';

import 'react-tooltip/dist/react-tooltip.css';

interface IProps {
    messages?: IMessage[];
    userUUID?: string;
    deleteText?: string;
    copyText?: string;
    successCopyText: string;
    handleDeleteMessage?: (_messageId: string) => void;
}

const { cn, isNonImageURL, isImage } = helpers;

const messageTypes = {
    text: 'text',
    image: 'image',
    link: 'link',
};

const ChatMessages = ({ messages, userUUID, deleteText, copyText, successCopyText, handleDeleteMessage }: IProps) => {
    const messagesContainerId = 'chat-messages-container';
    const [messageImage, setMessageImage] = useState<string>('');

    const renderMessages = useMemo(() => {
        return messages && messages.length > 0;
    }, [messages]);

    const memoizedMessages = useMemo(() => {
        const getMessageType = (message: IMessage) => {
            if (isNonImageURL(message?.text || '')) {
                return messageTypes.link;
            }

            return isImage(message?.text || '') ? messageTypes.image : messageTypes.text;
        };

        const constructUrl = (url: string) => {
            if (!url.match(/^https?:\/\//)) {
                return `https://${url}`;
            }

            return url;
        };

        const getURLMessage = (message: IMessage) => {
            return (
                <Link href={constructUrl(message?.text || '')} target="_blank" className="text-green-100">
                    {message?.text || ''}
                </Link>
            );
        };

        const getWrapperClass = (type: string, message: IMessage) => {
            if (type === messageTypes.text || type === messageTypes.link) {
                return cn('flex items-center p-12 rounded-[15px]', message?.user?._id === userUUID ? 'bg-green-400' : 'bg-grey-800');
            }

            return message?.user?._id === userUUID
                ? type === messageTypes.image
                    ? 'flex w-fit items-center justify-end'
                    : 'flex w-full items-center justify-end'
                : 'flex w-fit items-center justify-start';
        };

        const copyToClipboard = async (text?: string) => {
            try {
                await navigator.clipboard.writeText(text || '');
                toast.success(successCopyText);
            } catch (err) {
                console.error('Failed to copy: ', err);
            }
        };

        if (renderMessages) {
            return messages?.map((message: IMessage, index) => {
                const type = getMessageType(message);

                return (
                    <div key={`chat-message-${index}`} className={cn('w-[90%] md:w-[60%] mb-24', styles['chat-message'], message?.user?._id === userUUID ? 'ml-[auto]' : '')}>
                        <div
                            className={cn('flex items-center justify-end', type === messageTypes.link ? styles['link-wrapper'] : '', message?.user?._id === userUUID ? 'justify-end' : 'justify-start')}
                        >
                            {message?.user?._id === userUUID && (
                                <div className="relative">
                                    <Tooltip id={`chat-message-${index}`} clickable={true} className={messageImage ? 'z-0' : 'z-[99999]'} isOpen>
                                        <span className="cursor-pointer p-8" onClick={() => copyToClipboard(message?.text)}>
                                            {copyText}
                                        </span>
                                        <span
                                            className="cursor-pointer p-8"
                                            onClick={() => {
                                                if (handleDeleteMessage) {
                                                    handleDeleteMessage(message?._id);
                                                }
                                            }}
                                        >
                                            {deleteText}
                                        </span>
                                    </Tooltip>
                                    <Icon data-tooltip-id={`chat-message-${index}`} name="more-vertical" className={cn('mr-4 hidden w-[24px] h-[24px]', styles['icon'])} data-tooltip-content="" />
                                </div>
                            )}
                            <div className={getWrapperClass(type, message)}>
                                {type === messageTypes.image && (
                                    <Image src={message?.text} alt="chat-image" width={250} height={100} className="rounded-[9px] cursor-pointer" onClick={() => setMessageImage(message?.text)} />
                                )}
                                {type === messageTypes.link && getURLMessage(message)}
                                {type === messageTypes.text && message?.text && <Emoji text={message?.text || ''} />}
                            </div>
                        </div>
                        {message?.createdAt && (
                            <p className={cn('mt-8 text-grey-400 text-[12px] font-light', message?.user?._id === userUUID ? 'float-right' : '')}>
                                {format(new Date(message.createdAt), 'dd.MM.yyyy, HH:mm')}
                            </p>
                        )}
                    </div>
                );
            });
        }

        return null;
        // eslint-disable-next-line
    }, [messages, userUUID, renderMessages, messageImage]);

    useEffect(() => {
        const element = document.getElementById(messagesContainerId);

        if (element) {
            element.scrollTop = element.scrollHeight;
        }
    }, [messages]);

    return (
        <div className="mt-24">
            {messageImage && (
                <Modal
                    size="medium"
                    className="min-w-[95%] md:min-w-[500px] p-[0px] max-w-[500px]"
                    wrapperClassName="p-[0px]"
                    customCloseButton={
                        <div
                            className="rounded-[50%] z-[999999] top-[12px] right-[12px] absolute cursor-pointer bg-white-main flex items-center justify-center p-4"
                            onClick={() => setMessageImage('')}
                        >
                            <Icon name="x" />
                        </div>
                    }
                >
                    <Image src={messageImage} alt="selected-chat-image" className="rounded-[9px] !w-full !h-full !relative z-[99999]" fill objectFit="cover" />
                </Modal>
            )}
            <div
                className="overflow-y-auto bg-cover bg-center w-full flex flex-col pb-[70px]"
                style={{ backgroundImage: `url(/images/chat-background.png)`, height: 'calc(100vh - 250px)' }}
                id={messagesContainerId}
            >
                {memoizedMessages}
            </div>
        </div>
    );
};

export default ChatMessages;
