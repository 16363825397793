'use client';

import Image from 'next/image';

import { helpers } from 'common';
import userImage from 'components/avatar/assets/user.png';

const { cn, shortenText } = helpers;

interface IProps {
    username?: string;
    imageUrl?: string;
    className?: string;
    width?: number;
    height?: number;
    usernameMaxLength?: number;
    wrapperClassName?: string;
    bottomComponent?: React.ReactNode;
    rightIcon?: React.ReactNode;
    notificationsCount?: number;
}

const Avatar = ({ username, imageUrl, className, width, height, usernameMaxLength, wrapperClassName, bottomComponent, rightIcon, notificationsCount }: IProps) => {
    return (
        <div className={cn('flex items-center', wrapperClassName || '')}>
            <Image
                src={imageUrl || userImage}
                width={width || 32}
                height={height || 32}
                className={cn('rounded-[50%]', className || '!w-[32px] !h-[32px]')}
                alt={`user-avatar-image-${username || ''}`}
                style={{ objectFit: 'cover' }}
            />
            {(username || bottomComponent) && (
                <div className="ml-12">
                    {username && <p className="text-black-main text-sm-title">{usernameMaxLength ? shortenText(username, usernameMaxLength) : username}</p>}
                    {bottomComponent ? bottomComponent : null}
                </div>
            )}
            {(notificationsCount || 0) > 0 && (
                <div className="bg-red-200 p-4 rounded-[50%] flex items-center justify-center ml-[9px] w-[24px] h-[24px] text-white-main text-[12px] font-normal">{notificationsCount}</div>
            )}
            {rightIcon && <div className="ml-[9px] flex items-center">{rightIcon}</div>}
        </div>
    );
};

export default Avatar;
