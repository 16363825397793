'use client';

import { format } from 'date-fns';

import { Icon } from 'components';
import { Icons } from 'components/icon/types';
import { helpers } from 'common';
import { useTranslation } from 'i18n/client';

interface IProps {
    lang: string;
    ns?: string;
    icon: Icons;
    label: string;
    date: string;
    isHighlighted?: boolean;
    iconClass?: string;
    subtitle?: string;
}

const { cn } = helpers;

const OrderStatusItem = ({ lang, ns, icon, label, date, isHighlighted, iconClass, subtitle }: IProps) => {
    const { t } = useTranslation(lang, ns || 'orders');

    return (
        <div className={cn('p-12 rounded-[9px]', isHighlighted ? 'bg-green-400 border-2 border-green-100' : 'bg-grey-700')}>
            <div className="flex items-center">
                <Icon name={icon} className={iconClass || ''} />
                <div className="ml-16">
                    <p className="text-sm-title text-black-100 mb-8">{t(label)}</p>
                    <p className="text-[14px] text-grey-300">{subtitle ? subtitle : date ? format(new Date(date), 'dd.MM.yyyy') : ''}</p>
                </div>
            </div>
        </div>
    );
};

export default OrderStatusItem;
