'use client';

import { useMemo, useState, useRef, useEffect, useCallback } from 'react';

import { Label, Icon, RenderIf, SearchInput } from 'components';
import { ISelectOption } from 'common/types';
import { helpers } from 'common';

interface IProps {
    name: string;
    value?: string | number | number[] | string[] | null;
    onChange?: (_name: string, _value?: string | number, _values?: number[] | string[]) => void;
    onBlur?: (_name: string, _value?: string | number, _values?: number[] | string[]) => void;
    label?: string;
    placeholder?: string;
    id?: string;
    error?: string;
    touched?: boolean;
    options: ISelectOption[];
    displayValue?: boolean;
    fullWidth?: boolean;
    multiOptions?: string;
    withSearch?: boolean;
    handleSearch?: (_value?: string) => void;
    serchPlaceholder?: string;
    searchValue?: string;
    required?: boolean;
    className?: string;
    wrapperClassName?: string;
    listClassName?: string;
    listWrapperClassName?: string;
    emptyPlaceholder?: string;
    isMulti?: boolean;
    dropdownWrapperClassName?: string;
}

const { cn } = helpers;

const Select = ({
    name,
    value,
    onChange,
    onBlur,
    label,
    placeholder,
    id,
    error,
    touched,
    options,
    displayValue,
    fullWidth,
    multiOptions,
    withSearch,
    handleSearch,
    serchPlaceholder,
    required,
    className,
    wrapperClassName,
    listClassName,
    listWrapperClassName,
    emptyPlaceholder,
    isMulti,
    dropdownWrapperClassName,
}: IProps) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const dropdownRef = useRef<any>();
    const shouldRenderDropdown = !!(options && options.length) || emptyPlaceholder || withSearch;

    const selected = useMemo(() => {
        if (value) {
            let item;

            if (multiOptions) {
                for (let i = 0; i < options.length; i++) {
                    const option = options[i];
                    const multiOptionOptions = Object.assign([], option?.[multiOptions as keyof typeof option]);
                    item = multiOptionOptions.find((item: any) => item.id === value);

                    if (item) {
                        break;
                    }
                }
            } else {
                item = options.find((option: ISelectOption) => option.value === value);
            }

            return item;
        }

        return null;
    }, [value, options, multiOptions]);

    const handleChange = (selectedValue: string | number, option?: ISelectOption) => {
        if (typeof onChange === 'function') {
            if (isMulti && option) {
                const selectedOptions: any = value && Array.isArray(value) ? Object.assign([], value) : [];
                const index = selectedOptions.findIndex((item: number | string) => item.toString() === (option.id || option.value).toString());

                if (index > -1) {
                    selectedOptions.splice(index, 1);
                } else {
                    selectedOptions.push(option.id || option.value);
                }

                onChange(name, '', selectedOptions);
                return;
            }

            onChange(name, selectedValue);
        }

        if (typeof onBlur === 'function') {
            if (isMulti && option) {
                const selectedOptions: any = value && Array.isArray(value) ? Object.assign([], value) : [];
                const index = selectedOptions.findIndex((item: number | string) => item.toString() === (option.id || option.value).toString());

                if (index > -1) {
                    selectedOptions.splice(index, 1);
                } else {
                    selectedOptions.push(option.id || option.value);
                }

                onBlur(name, '', selectedOptions);
                return;
            }

            onBlur(name, selectedValue);
        }

        if (!isMulti) {
            setIsOpen(false);
        }

        if (typeof handleSearch === 'function') {
            handleSearch('');
        }
    };

    const memoizedHandleChange = useCallback(handleChange, [name, onBlur, onChange, handleSearch, isMulti, value]);

    const handleClickListener = (e: any) => {
        const clickedInside = dropdownRef && dropdownRef.current && dropdownRef.current.contains(e.target);

        if (clickedInside) {
            return;
        }

        setIsOpen(false);

        if (typeof handleSearch === 'function') {
            handleSearch('');
        }
    };

    const memoizedHandleClickListener = useCallback(handleClickListener, [handleSearch]);

    const memoizedOptions = useMemo(() => {
        return options.map(
            (option: ISelectOption) =>
                option.component || (
                    <li key={`select-option-${option.value}`} className="py-4" onClick={() => memoizedHandleChange(option.value, option)}>
                        <button type="button" className="inline-flex ml-auto w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-600 dark:hover:text-white">
                            <div className="inline-flex items-end">
                                {option.leftElement ? option.leftElement : null} <span className="ml-4">{option.label}</span>
                            </div>
                        </button>
                    </li>
                ),
        );
    }, [options, memoizedHandleChange]);

    const memoizedMultiOptions = useMemo(() => {
        const isSelected = (option: any) => {
            return value && Array.isArray(value) ? value.findIndex((item: string | number) => item.toString() === option.id.toString()) > -1 : false;
        };

        const getMultiOptions = (option: any) => {
            if (option && option[multiOptions as keyof typeof option]) {
                return option[multiOptions as keyof typeof option].map((item: any, index: number) => (
                    <button
                        key={`multi-option-${index}`}
                        type="button"
                        className={cn('inline-flex ml-auto w-full px-12 py-12 border-b border-b-grey-800 text-sm-title', isSelected(item) ? 'text-green-100' : 'text-grey-300')}
                        onClick={() => memoizedHandleChange(item.id, item)}
                    >
                        {item.name}
                    </button>
                ));
            }
        };

        return options.map(
            (option: ISelectOption) =>
                option.component || (
                    <li key={`select-option-${option.value}`} className="py-4">
                        <div className="w-full px-4 py-2">
                            <div className="inline-flex items-end w-full border-b border-b-grey-800 py-12">
                                <span className="text-sm-title font-medium text-black-100">{option.label}</span>
                            </div>
                            {getMultiOptions(option)}
                        </div>
                    </li>
                ),
        );
    }, [options, memoizedHandleChange, multiOptions, value]);

    const memoizedSelectedValue = useMemo(() => {
        const getMultiSelected = () => {
            if (value && Array.isArray(value) && value.length > 0) {
                let item = '';

                if (multiOptions) {
                    for (let i = 0; i < options.length; i++) {
                        const option = options[i];
                        const multiOptionOptions = Object.assign([], option?.[multiOptions as keyof typeof option]);

                        if (multiOptionOptions && multiOptionOptions.length > 0) {
                            for (let j = 0; j < multiOptionOptions.length; j++) {
                                const multiItem: any = multiOptionOptions[j];
                                const selectedItem: any = value.findIndex((sel: number | string) => sel.toString() === multiItem?.id?.toString()) > -1 ? multiItem : null;

                                if (selectedItem) {
                                    item = !item.length ? selectedItem.name : `${item}, ${selectedItem.name}`;
                                }
                            }
                        }
                    }
                } else {
                    for (let i = 0; i < options.length; i++) {
                        if (value.findIndex((sel: string | number) => sel.toString() === options[i].value.toString()) > -1) {
                            item = !item.length ? options[i].label : `${item}, ${options[i].label}`;
                        }
                    }
                }

                return item;
            }

            return <span className="font-light text-grey-2800 text-[14px]">{placeholder}</span>;
        };

        return (
            <>
                {selected?.leftElement ? selected.leftElement : null}{' '}
                {isMulti ? (
                    <span className="ml-8 text-sm-title font-light whitespace-nowrap">{getMultiSelected()}</span>
                ) : (
                    <span className="ml-8 text-sm-title font-light whitespace-nowrap">{displayValue ? selected?.value : selected?.label || selected?.name}</span>
                )}
            </>
        );
    }, [selected, displayValue, isMulti, value, multiOptions, options, placeholder]);

    useEffect(() => {
        document.addEventListener('mousedown', memoizedHandleClickListener);

        return () => {
            document.removeEventListener('mousedown', memoizedHandleClickListener);
        };
    }, [memoizedHandleClickListener]);

    return (
        <div className={cn('pb-32', wrapperClassName || '')}>
            {label && (
                <div className={error && touched ? 'text-red-100' : 'text-grey-100'}>
                    <Label htmlFor={id || name || 'states-button'}>{label}</Label>
                    {required && <span className="ml-2 font-light text-red-200">*</span>}
                </div>
            )}
            <div className={cn('flex mt-8', dropdownWrapperClassName || '')}>
                <button
                    id={id || name || 'states-button'}
                    data-dropdown-toggle="dropdown-states"
                    className={cn(
                        'flex-shrink-0 inline-flex items-center text-center text-gray-500 bg-gray-100 border-b py-16',
                        error && touched ? 'border-b-red-100' : 'border-b-grey-200',
                        fullWidth ? 'w-full' : '',
                        className || '',
                    )}
                    type="button"
                    onClick={() => setIsOpen(!isOpen)}
                >
                    {Array.isArray(value) || selected?.value || selected?.id ? (
                        <div className="flex items-center overflow-x-auto">{memoizedSelectedValue}</div>
                    ) : (
                        <span className="font-light text-grey-2800 text-[14px]">{placeholder}</span>
                    )}
                    <Icon name="chevron-down" className={fullWidth ? 'ml-[auto]' : 'ml-24'} />
                </button>
            </div>
            {shouldRenderDropdown && (
                <div className="relative">
                    <div
                        ref={dropdownRef}
                        id="dropdown-states"
                        className={cn(
                            'z-[9999] bg-white-main absolute overflow-y-auto top-0 p-8 border border-grey-200 divide-y divide-gray-100 min-w-fit w-full',
                            isOpen ? '' : 'hidden',
                            withSearch && handleSearch ? 'max-h-[300px]' : 'max-h-[200px]',
                            listWrapperClassName || '',
                        )}
                    >
                        <>
                            {withSearch && typeof handleSearch === 'function' && (
                                <div>
                                    <SearchInput onChange={handleSearch} placeholder={serchPlaceholder} wrapperClassName="pb-12" />
                                </div>
                            )}
                            <RenderIf show={!multiOptions && options && options.length > 0}>
                                <ul className={cn('py-2 text-sm text-gray-700 min-w-max dark:text-gray-200', listClassName || '')} aria-labelledby="states-button">
                                    {memoizedOptions}
                                </ul>
                            </RenderIf>
                            <RenderIf show={!!multiOptions}>
                                <ul className={cn('py-2 text-sm text-gray-700 min-w-max dark:text-gray-200 border-none', listClassName || '')} aria-labelledby="states-button">
                                    {memoizedMultiOptions}
                                </ul>
                            </RenderIf>
                            {(!options || !options.length) && emptyPlaceholder && (
                                <div className="p-4">
                                    <p className="text-sm text-gray-700">{emptyPlaceholder}</p>
                                </div>
                            )}
                        </>
                    </div>
                </div>
            )}
            {error && touched && (
                <div className="relative mt-4">
                    <p className="text-error text-red-100 absolute top-[0px]">{error}</p>
                </div>
            )}
        </div>
    );
};

export default Select;
