'use client';

import { useMemo } from 'react';

import { Label } from 'components';
import { helpers } from 'common';

interface ITab {
    label: string;
    value: string | number | boolean;
}

interface IProps {
    tabs: ITab[];
    tab?: string | number | boolean;
    onChange: (_tab: string | number | boolean) => void;
    tabClassName?: string;
    label?: string;
    error?: string;
    touched?: boolean;
    required?: boolean;
    wrapperClassName?: string;
    tabWrapperClassName?: string;
    id?: string;
    name?: string;
    hideSeparator?: boolean;
    labelClassNameInactive?: string;
}

const { cn } = helpers;

const Tabs = ({ tab, tabs, onChange, tabClassName, label, error, touched, required, wrapperClassName, tabWrapperClassName, id, name, hideSeparator, labelClassNameInactive }: IProps) => {
    const tabsMemoized = useMemo(() => {
        const isSelected = (item: ITab) => {
            return tab?.toString() === item.value.toString() || false;
        };

        return tabs.map((item: ITab, index: number) => (
            <div
                className={cn(
                    'py-16 w-200 cursor-pointer flex items-center justify-center',
                    isSelected(item) ? 'bg-green-100 text-xsm-title-light text-white-main font-light' : 'bg-green-400 xsm-title-light font-normal text-black-main',
                    !index ? 'rounded-tl-[6px] rounded-bl-[6px]' : '',
                    index === tabs.length - 1 ? 'rounded-tr-[6px] rounded-br-[6px]' : '',
                    tabs[index - 1] && tabs[index - 1].value !== tab ? 'border-l-0' : '',
                    tabClassName || '',
                    hideSeparator && index < tabs.length - 1 ? 'border-r border-r-green-100' : '',
                )}
                onClick={() => onChange(item.value)}
                key={`tab-item-${item.value}`}
            >
                <div
                    className={cn(
                        'w-full flex justify-center',
                        !hideSeparator && tabs[index + 1] && tab !== tabs[index + 1]?.value && index < tabs.length - 1 ? 'border-r border-r-green-500' : '',
                        labelClassNameInactive && !isSelected(item) ? labelClassNameInactive : '',
                    )}
                >
                    {item.label}
                </div>
            </div>
        ));
    }, [tabs, tab, onChange, tabClassName, hideSeparator, labelClassNameInactive]);

    return (
        <div className={wrapperClassName || ''}>
            {label && (
                <div className={error && touched ? 'text-red-100' : 'text-grey-100'}>
                    <Label>{label}</Label>
                    {required && <span className="ml-2 font-light text-red-200">*</span>}
                </div>
            )}
            <div id={id || name || ''} className={cn('flex overflow-x-auto', label ? 'mt-16' : '', error && touched ? 'border border-red-100 rounded-[6px]' : '', tabWrapperClassName || '')}>
                {tabsMemoized}
            </div>
            {error && touched && (
                <div className="relative mt-4">
                    <p className="text-error text-red-100 absolute top-[-4px]">{error}</p>
                </div>
            )}
        </div>
    );
};

export default Tabs;
