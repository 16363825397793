'use client';

import { useMemo } from 'react';
import { useHydrated } from 'react-hydration-provider';

import { helpers } from 'common';
import { DEFAULT_COLOR, ROTATE_OPTIONS } from 'components/category-item/constants';

interface IProps {
    id: number;
    title: string;
    imageUrl?: string;
    className?: string;
    onChange: (_id: number) => void;
    selected?: string | number | null;
    color?: string;
    iconClassName?: string;
    pinClassName?: string;
    pinStyle?: object;
}

const { cn, hexToRgb, getRandomElementFromArray, shortenText } = helpers;

const CategoryItem = ({ id, title, imageUrl, className, onChange, selected, color, iconClassName, pinClassName, pinStyle }: IProps) => {
    type ColorMap = { [key: string]: string };
    const colorMap: ColorMap = {
        '#F1FDEF': '#D4E8D0',
        '#DCF5F5': '#A1E1E1',
        '#FAF4E9': '#FFD786',
        '#F2F6F6': '#CDDADA',
        '#F4F4F4': '#D9D9D9',
        '#FCEEF1': '#FF557C',
        '#F1FBFB': '#CDDADA',
    };

    const transformColor = (inputColor: string): string => {
        const upperCaseColor = inputColor.toUpperCase();
        return colorMap[upperCaseColor] || inputColor;
    };

    const hydrated = useHydrated();

    const getColor = (selectedColor?: string, withOpaciti?: boolean) => {
        const result = hexToRgb(selectedColor || DEFAULT_COLOR, { r: 241, g: 253, b: 239 });

        return withOpaciti ? `rgba(${result.r}, ${result.g}, ${result.b}, 0.6)` : `rgb(${result.r}, ${result.g}, ${result.b})`;
    };

    const bgColor = useMemo(() => {
        return getColor(color);
    }, [color]);

    const pingBgColor = useMemo(() => {
        return transformColor(color ?? DEFAULT_COLOR);
    }, [color]);

    const rotate = useMemo(() => {
        return getRandomElementFromArray(ROTATE_OPTIONS);
    }, []);

    if (!hydrated) {
        return null;
    }

    return (
        <div
            className={cn('flex flex-col px-12 py-24 rounded-[9px] h-max w-full cursor-pointer relative', className || '')}
            onClick={() => onChange(id)}
            style={{
                background: bgColor,
                border: selected === id ? '3px solid #64B054' : '3px solid transparent',
            }}
        >
            <p className="text-xsm-title-light text-black-100 mb-32 z-9999 break-words">{shortenText(title, 48)}</p>
            <div
                className={cn('w-[90px] h-[45px] absolute right-[40px] bottom-[40px] rounded-[25px]', pinClassName || '')}
                style={{ ...{ background: pingBgColor, transform: `rotate(${rotate}deg)` }, ...(pinStyle || {}) }}
            ></div>
            <img className={cn('max-w-[50%] absolute right-[16px] bottom-[16px]', iconClassName || '')} src={imageUrl} alt={`category-item-icon-${id}`} />
        </div>
    );
};

export default CategoryItem;
