import { helpers } from 'common';
import styles from 'components/loader/styles/loader.module.scss';
import { DEFAULT_SIZE } from 'components/loader/constants';

interface IProps {
    className?: string;
    size?: number;
}

const { cn } = helpers;

const Loader = ({ className, size }: IProps) => {
    // @ts-ignore
    const inlineStyles: { [key: string]: React.CSSProperties } = { styles: { '--spinner-width': `${size || DEFAULT_SIZE}px` } };

    return <span className={cn(styles.loader, styles[className || ''] || '')} style={inlineStyles.styles}></span>;
};

export default Loader;
