'use client';

import { useState, useRef, useCallback, useEffect, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import Link from 'next/link';

import { compressQueryParams } from 'app/[locale]/orders/[type]/helpers/orders-helpers';
import { IUser, IInfoModalData } from 'common/types';
import { Avatar, Button, Icon, InfoModal, Loader, NotificationItem } from 'components';
import { helpers, routesConstants, constants, redirectTo, queryKeys } from 'common';
import { setUserDetails } from 'core';
import { useTranslation } from 'i18n/client';
import { userAPI, orderAPI } from 'api';
import { INotification } from 'api/user/types';

interface IProps {
    lang: string;
    userDetails?: IUser;
    handleLogout: () => void;
    triggerItem?: React.ReactNode;
    isMobile?: boolean;
    handleOpenRules?: () => void;
    isUserLoggedIn?: boolean;
    handleSetNotLoggedInModalData: (_title: string, _message: string) => void;
    handleGoToBanners?: () => void;
}

const { cn } = helpers;
const { routes, prefixes } = routesConstants;
const { getNotificationsCountAPI, getNotificationsAPI, makeNotificationViewedAPI, getUserDetailsAPI } = userAPI;
const { getOrderBuyerAPI, getOrderDetailsAPI } = orderAPI;

const {
    notificationTypeOfferAccepted,
    notificationTypeOfferCreated,
    notificationTypeOfferRejected,
    notificationTypeOrderAccepted,
    notificationTypeProductPromotionExpired,
    notificationTypeVerificationRejected,
    notificationTypeVerificationAccepted,
    notificationTypeBannerAccepted,
    notificationTypeBannerExpired,
    notificationTypeBannerDeclined,
    notificationTypeReportCreated,
    notificationTypeOrderCreated,
    notificationTypeOrderRejected,
    notificationTypeAddressApproved,
    notificationTypeAddressRejected,
    notificationTypeBankTransferAccepted,
    notificationTypeBankTransferRejected,
} = constants.NOTIFICATION_TYPES;

const UserMenuItem = ({
    lang,
    userDetails: userDetailsInitialValues,
    handleLogout,
    triggerItem,
    isMobile,
    handleOpenRules,
    isUserLoggedIn,
    handleSetNotLoggedInModalData,
    handleGoToBanners,
}: IProps) => {
    const ref = useRef<any>();
    const triggerRef = useRef<any>();
    const { t } = useTranslation(lang, 'header');
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [modalData, setModalData] = useState<IInfoModalData>({ isOpen: false, title: '', message: '' });
    const [notificationModalData, setNotificationModalData] = useState<IInfoModalData>({ isOpen: false, title: '', message: '' });
    const [notificationsOpen, setNotificationsOpen] = useState<boolean>(false);
    const [userDetails, setUserDetailsInState] = useState<IUser | undefined>(userDetailsInitialValues);

    const { data: notificationsCount, refetch } = useQuery({
        queryKey: [queryKeys.GET_NOTIFICATIONS_COUNT],
        queryFn: () => getNotificationsCountAPI(),
        enabled: false,
    });

    const {
        data,
        refetch: refetchNotifications,
        isFetched,
    } = useQuery({
        queryKey: [queryKeys.GET_NOTIFICATIONS],
        queryFn: () => getNotificationsAPI({ page: 1, perPage: Number.MAX_SAFE_INTEGER }),
        enabled: false,
    });

    const handleClickListener = (e: any) => {
        const clickedInside = ref && ref.current && ref.current.contains(e.target);
        const clickedOnTrigger = triggerRef && triggerRef.current && triggerRef.current.contains(e.target);

        if (clickedInside || clickedOnTrigger) {
            return;
        }

        setIsOpen(false);
    };

    const memoizedHandleClickListener = useCallback(handleClickListener, []);

    const isUserVerified = useMemo(() => {
        return userDetails?.verificationStatus === constants.USER_STATUSES.verified.value;
    }, [userDetails]);

    const isInPendingStatus = useMemo(() => {
        return userDetails?.verificationStatus === constants.USER_STATUSES.pending.value;
    }, [userDetails]);

    const openNotifications = () => {
        setNotificationsOpen(true);
        refetchNotifications();
    };

    const makeNotificationViewed = async (uuid: string) => {
        try {
            await makeNotificationViewedAPI([uuid]);
        } catch (error) {
            console.log('Error making notification viewed.');
        }
    };

    const handleGoToOrder = async (notification: INotification, isBuyer?: boolean) => {
        try {
            const order = isBuyer ? await getOrderBuyerAPI(notification?.reference || '') : await getOrderDetailsAPI(notification?.reference || '');
            redirectTo(
                `/${lang}${routes[prefixes.orderDetails].path}/${notification?.reference || ''}?params=${compressQueryParams(
                    order?.buyerUserUUID || order?.buyerUUID || '',
                    order?.product?.sellerUserUUID,
                )}`,
            );
        } catch (error) {
            console.log('Error fetching order details');
        }
    };

    const handleNotificationClick = async (notification: INotification) => {
        if (!notification.viewed) {
            await makeNotificationViewed(notification.uuid);
            refetch();
            refetchNotifications();
        }

        switch (notification.notificationTypeID) {
            case notificationTypeOfferAccepted.value:
                setIsOpen(false);
                redirectTo(`/${lang}${routes[prefixes.orders].path}/bids`);
                break;
            case notificationTypeOfferRejected.value:
                setIsOpen(false);
                redirectTo(`/${lang}${routes[prefixes.orders].path}/purchases?active=false`);
                break;
            case notificationTypeOfferCreated.value:
                setIsOpen(false);
                redirectTo(`/${lang}${routes[prefixes.orders].path}/offers`);
                break;
            case notificationTypeVerificationRejected.value:
                if (userDetails?.verificationStatus === constants.USER_STATUSES.declined.value) {
                    setNotificationModalData({
                        isOpen: true,
                        title: t('NOTIFICATION_TYPE_VERIFICATION_REJECTED'),
                        message: t('NOTIFICATION_TYPE_VERIFICATION_REJECTED_MESSAGE'),
                    });
                } else {
                    setModalData({
                        isOpen: true,
                        title: userDetails?.verificationStatus === constants.USER_STATUSES.pending.value ? t('PENDING_VERIFICATION_TITLE') : t('ACCEPTED_VERIFICATION_TITLE'),
                        message: userDetails?.verificationStatus === constants.USER_STATUSES.pending.value ? t('PENDING_VERIFICATION_MESSAGE') : t('ACCEPTED_VERIFICATION_MESSAGE'),
                    });
                }
                break;
            case notificationTypeVerificationAccepted.value:
                setIsOpen(false);
                redirectTo(`/${lang}${routes[prefixes.settings].path}`);
                break;
            case notificationTypeBannerAccepted.value:
            case notificationTypeBannerExpired.value:
            case notificationTypeBannerDeclined.value:
                setIsOpen(false);
                redirectTo(`/${lang}${routes[prefixes.banners].path}?showBanners=true`);
                break;
            case notificationTypeReportCreated.value:
            case notificationTypeProductPromotionExpired.value:
                setIsOpen(false);
                redirectTo(`/${lang}${routes[prefixes.product].path}/${notification.reference}`);
                break;
            case notificationTypeOrderCreated.value:
                setIsOpen(false);
                handleGoToOrder(notification, false);
                break;
            case notificationTypeOrderRejected.value:
            case notificationTypeOrderAccepted.value:
                setIsOpen(false);
                handleGoToOrder(notification, true);
                break;
            case notificationTypeAddressApproved.value:
            case notificationTypeAddressRejected.value:
                setModalData({
                    isOpen: true,
                    title: notification.notificationTypeID === notificationTypeAddressApproved.value ? t('ADDRESS_VERIFIED_TITLE') : t('ADDRESS_REJECTED_TITLE'),
                    message: notification.notificationTypeID === notificationTypeAddressApproved.value ? t('ADDRESS_VERIFIED_MESSAGE') : t('ADDRESS_REJECTED_MESSAGE'),
                    buttonText: notification.notificationTypeID === notificationTypeAddressApproved.value ? 'OK' : t('ADD_NEW_ADDRESS'),
                    handleButtonClick:
                        notification.notificationTypeID === notificationTypeAddressApproved.value
                            ? undefined
                            : () => {
                                  setModalData({
                                      isOpen: false,
                                      title: '',
                                      message: '',
                                  });

                                  redirectTo(`/${lang}/verify/address`);
                              },
                });
                break;
            case notificationTypeBankTransferAccepted.value:
            case notificationTypeBankTransferRejected.value:
                setIsOpen(false);
                redirectTo(`/${lang}${routes[prefixes.wallet].path}`);
                break;
            default:
                break;
        }
    };

    const language = useMemo(() => {
        return constants.LANGUAGES.find((item) => item.code === lang);
    }, [lang]);

    const memoizedUserVerified = useMemo(() => {
        if (isInPendingStatus) {
            return (
                <div className="flex items-center mt-4">
                    <Icon name="info" className="mr-4" />
                    <p className="text-xsm-title text-grey-300 font-light">{t('PENDING_VERIFICATION')}</p>
                </div>
            );
        }

        if (isUserVerified) {
            return (
                <div className="flex items-center mt-4">
                    <div className="flex items-center justify-center mr-4 p-4 bg-green-100 rounded-[50%]">
                        <Icon name="check" className="white-icon w-[16px] h-[16px]" />
                    </div>
                    <p className="text-xsm-title text-grey-300 font-light">{t('VERIFIED')}</p>
                </div>
            );
        }

        return (
            <div className="flex items-center mt-4">
                <Icon name="x-octagon" className="mr-4" />
                <p className="text-xsm-title text-grey-300 font-light">{t('NOT_VERIFIED')}</p>
            </div>
        );
    }, [isUserVerified, isInPendingStatus, t]);

    const memoizedTriggerItem = useMemo(() => {
        if (triggerItem) {
            return (
                <div
                    onClick={() => {
                        setNotificationsOpen(false);
                        setIsOpen((current: boolean) => !current);
                    }}
                >
                    {triggerItem}
                </div>
            );
        }

        const handleGetUserDetails = async () => {
            try {
                const response = await getUserDetailsAPI();
                setUserDetails(Object.assign({}, response?.data));
                setUserDetailsInState(Object.assign({}, response?.data));
            } catch (error) {
                console.log('Error happened while fetching user details.');
            }
        };

        return (
            <div
                onClick={async () => {
                    await handleGetUserDetails();
                    setIsOpen((current: boolean) => !current);
                    setNotificationsOpen(false);
                }}
                className="cursor-pointer"
            >
                <Avatar
                    username={userDetails?.username}
                    imageUrl={userDetails?.profileImage}
                    width={36}
                    height={36}
                    wrapperClassName="py-8 rounded-[9px]"
                    className="w-[36px] h-[36px]"
                    usernameMaxLength={12}
                    notificationsCount={notificationsCount}
                    rightIcon={isOpen ? <Icon name="chevron-up" className="cursor-pointer" /> : <Icon name="chevron-down" className="cursor-pointer" />}
                />
            </div>
        );
    }, [triggerItem, isOpen, userDetails, notificationsCount]);

    useEffect(() => {
        document.addEventListener('mousedown', memoizedHandleClickListener);

        return () => {
            document.removeEventListener('mousedown', memoizedHandleClickListener);
        };
    }, [memoizedHandleClickListener]);

    useEffect(() => {
        if (isUserLoggedIn) {
            refetch();
        }
    }, [refetch, isUserLoggedIn]);

    return (
        <div>
            <InfoModal {...modalData} handleClose={() => setModalData({ isOpen: false, title: '', message: '' })} />
            <InfoModal
                {...notificationModalData}
                handleClose={() => setNotificationModalData({ isOpen: false, title: '', message: '' })}
                buttonText={t('START_VERIFICATION')}
                handleButtonClick={() => {
                    setIsOpen(false);
                    redirectTo(`/${lang}${routes[prefixes.kyc].path}/${userDetails?.uuid}`);
                }}
            />
            <div ref={triggerRef}>{memoizedTriggerItem}</div>
            <div className="relative">
                <div
                    ref={ref}
                    id="user-menu-dropdown"
                    style={{ boxShadow: '0px 6px 10px 0px rgba(0, 0, 0, 0.08), 0px 1px 18px 0px rgba(0, 0, 0, 0.06), 0px 3px 5px 0px rgba(0, 0, 0, 0.10)' }}
                    className={cn(
                        'z-[9999] bg-white-main absolute overflow-y-auto top-0 right-0 left-0 md:top-[0px] lg:left-[initial] xl:right-[-45px] md:p-24 w-[94vw] md:w-[400px] max-h-[90vh]',
                        isOpen ? '' : 'hidden',
                        notificationsOpen ? '!p-[0px]' : 'p-16',
                    )}
                >
                    {!isUserLoggedIn && isMobile && (
                        <>
                            <div
                                className="flex items-center mb-24 cursor-pointer"
                                onClick={() => {
                                    setIsOpen(false);
                                    handleSetNotLoggedInModalData('NOT_LOGGED_IN_ORDERS_TITLE', 'NOT_LOGGED_IN_ORDERS_SUBTITLE');
                                }}
                            >
                                <div className="mr-12 bg-green-400 p-16">
                                    <Icon name="order" className="green-icon" />
                                </div>
                                <p className="text-sm-title text-black-100 font-light">{t('ORDERS')}</p>
                            </div>
                        </>
                    )}
                    {isUserLoggedIn && (
                        <>
                            {!notificationsOpen && (
                                <>
                                    <div className="mb-16">
                                        <Link href={`/${lang}${routes[prefixes.user].path}/${userDetails?.uuid}`} onClick={() => setIsOpen(false)}>
                                            <Avatar
                                                username={userDetails?.username}
                                                width={48}
                                                height={48}
                                                className="w-[48px] h-[48px]"
                                                imageUrl={userDetails?.profileImage}
                                                bottomComponent={memoizedUserVerified}
                                            />
                                        </Link>
                                    </div>
                                    {!isUserVerified && !isInPendingStatus && <p className="mt-24 text-grey-300 text-xsm-title-light">{t('USER_VERIFICATION_MESSAGE')}</p>}
                                    {!isUserVerified && !isInPendingStatus && (
                                        <Link href={`/${lang}${routes[prefixes.kyc].path}/${userDetails?.uuid || ''}`}>
                                            <Button variant="success" className="mt-8 w-full" onClick={() => setIsOpen(false)}>
                                                {t('BECOME_SELLER')}
                                            </Button>
                                        </Link>
                                    )}
                                    <div className="w-full h-[1px] bg-grey-800 my-24"></div>
                                    {!isUserVerified && !isInPendingStatus && (
                                        <Link href={`/${lang}${routes[prefixes.kyc].path}/${userDetails?.uuid || ''}`} onClick={() => setIsOpen(false)}>
                                            <div className="flex items-center mb-24">
                                                <div className="mr-12 bg-green-400 p-16">
                                                    <Icon name="shopping-cart" className="green-icon" />
                                                </div>
                                                <p className="text-sm-title text-black-100 font-light">{t('BECOME_SELLER')}</p>
                                            </div>
                                        </Link>
                                    )}
                                    {isMobile && (
                                        <Link href={`/${lang}${routes[prefixes.orders].path}/sells`} onClick={() => setIsOpen(false)}>
                                            <div className="flex items-center mb-24">
                                                <div className="mr-12 bg-green-400 p-16">
                                                    <Icon name="order" className="green-icon" />
                                                </div>
                                                <p className="text-sm-title text-black-100 font-light">{t('ORDERS')}</p>
                                            </div>
                                        </Link>
                                    )}
                                    <div className="flex items-center mb-24 cursor-pointer" onClick={openNotifications}>
                                        <div className="mr-12 bg-green-400 p-16">
                                            <Icon name="bell" className="green-icon" />
                                        </div>
                                        <p className="text-sm-title text-black-100 font-light">{t('NOTIFICATIONS')}</p>
                                        {(notificationsCount || 0) > 0 && (
                                            <div className="bg-red-200 p-4 rounded-[50%] flex items-center justify-center ml-[auto] w-[24px] h-[24px] text-white-main text-[12px] font-normal">
                                                {notificationsCount}
                                            </div>
                                        )}
                                    </div>
                                    {userDetails?.seller && (
                                        <Link href={`/${lang}${routes[prefixes.subscription].path}`} onClick={() => setIsOpen(false)}>
                                            <div className="flex items-center mb-24">
                                                <div className="mr-12 bg-green-400 p-16">
                                                    <Icon name="user-check" className="green-icon" />
                                                </div>
                                                <p className="text-sm-title text-black-100 font-light">{t('SUBSCRIPTION')}</p>
                                            </div>
                                        </Link>
                                    )}
                                    {userDetails?.seller && (
                                        <Link href={`/${lang}${routes[prefixes.wallet].path}`} onClick={() => setIsOpen(false)}>
                                            <div className="flex items-center mb-24">
                                                <div className="mr-12 bg-green-400 p-16">
                                                    <Icon name="wallet" className="green-icon" />
                                                </div>
                                                <p className="text-sm-title text-black-100 font-light">{t('WALLET')}</p>
                                            </div>
                                        </Link>
                                    )}
                                    <Link href={`/${lang}${routes[prefixes.settings].path}`} onClick={() => setIsOpen(false)}>
                                        <div className="flex items-center mb-24">
                                            <div className="mr-12 bg-green-400 p-16">
                                                <Icon name="settings" className="green-icon" />
                                            </div>
                                            <p className="text-sm-title text-black-100 font-light">{t('SETTINGS')}</p>
                                        </div>
                                    </Link>
                                    {isMobile && (
                                        <div
                                            className="flex items-center mb-24"
                                            onClick={() => {
                                                setIsOpen(false);

                                                if (handleGoToBanners) {
                                                    handleGoToBanners();
                                                }
                                            }}
                                        >
                                            <div className="mr-12 bg-green-400 p-16">
                                                <Icon name="shopping-cart" className="green-icon" />
                                            </div>
                                            <p className="text-sm-title text-black-100 font-light">{t('COMMERCIALS')}</p>
                                        </div>
                                    )}
                                    {isMobile && (
                                        <div
                                            onClick={() => {
                                                setIsOpen(false);

                                                if (handleOpenRules) {
                                                    handleOpenRules();
                                                }
                                            }}
                                        >
                                            <div className="flex items-center mb-24">
                                                <div className="mr-12 bg-green-400 p-16">
                                                    <Icon name="clipboard-list" className="green-icon" />
                                                </div>
                                                <p className="text-sm-title text-black-100 font-light">{t('RULES')}</p>
                                            </div>
                                        </div>
                                    )}
                                    <a href="mailto:podrska@naklik.ba" onClick={() => setIsOpen(false)}>
                                        <div className="flex items-center mb-24">
                                            <div className="mr-12 bg-green-400 p-16">
                                                <Icon name="headphones" className="green-icon" />
                                            </div>
                                            <p className="text-sm-title text-black-100 font-light">{t('SUPPORT')}</p>
                                        </div>
                                    </a>
                                    <div className="flex items-center cursor-pointer" onClick={handleLogout}>
                                        <div className="mr-12 bg-green-400 p-16">
                                            <Icon name="log-out" className="green-icon" />
                                        </div>
                                        <p className="text-sm-title text-black-100 font-light">{t('LOGOUT')}</p>
                                    </div>
                                </>
                            )}
                            {notificationsOpen && (
                                <>
                                    {isFetched && (
                                        <>
                                            {!data?.results ||
                                                (data.results.length == 0 && (
                                                    <div className="w-full py-[50px] flex items-center justify-center">
                                                        <p className="text-sm-title text-grey-300">{t('NOTIFICATIONS_EMPTY_STATE')}</p>
                                                    </div>
                                                ))}
                                            {data?.results && data.results.length > 0 && (
                                                <div className="w-full max-h-[500px] overflow-y-auto">
                                                    <div className="flex items-center cursor-pointer w-full px-16 py-24" onClick={() => setNotificationsOpen(false)}>
                                                        <Icon name="arrow-left" />
                                                        <p className="mx-[auto] text-[18px] text-black-main font-bold">{t('NOTIFICATIONS_TITLE')}</p>
                                                    </div>
                                                    {data?.results?.map((item: INotification) => (
                                                        <NotificationItem
                                                            key={`notification-item-${item.uuid}`}
                                                            lang={language?.shortened || ''}
                                                            notification={item}
                                                            t={t}
                                                            onClick={handleNotificationClick}
                                                        />
                                                    ))}
                                                </div>
                                            )}
                                        </>
                                    )}
                                    {!isFetched && (
                                        <div className="w-full py-[50px] flex items-center justify-center">
                                            <Loader className="loader-black" />
                                        </div>
                                    )}
                                </>
                            )}
                        </>
                    )}
                    {!isUserLoggedIn && (
                        <>
                            {isMobile && (
                                <>
                                    <div
                                        onClick={() => {
                                            setIsOpen(false);

                                            if (handleOpenRules) {
                                                handleOpenRules();
                                            }
                                        }}
                                    >
                                        <div className="flex items-center mb-24">
                                            <div className="mr-12 bg-green-400 p-16">
                                                <Icon name="clipboard-list" className="green-icon" />
                                            </div>
                                            <p className="text-sm-title text-black-100 font-light">{t('RULES')}</p>
                                        </div>
                                    </div>
                                    <a href={`mailto:${constants.SUPPORT_EMAIL}`} onClick={() => setIsOpen(false)}>
                                        <div className="flex items-center mb-24">
                                            <div className="mr-12 bg-green-400 p-16">
                                                <Icon name="headphones" className="green-icon" />
                                            </div>
                                            <p className="text-sm-title text-black-100 font-light">{t('SUPPORT')}</p>
                                        </div>
                                    </a>
                                </>
                            )}
                            <Link href={`/${lang}${routes[prefixes.login].path}`}>
                                <Button variant="secondary" className="w-full">
                                    {t('LOGIN')}
                                </Button>
                            </Link>
                            <Link href={`/${lang}${routes[prefixes.registration].path}`}>
                                <Button variant="success" className="mt-16 w-full">
                                    {t('REGISTER')}
                                </Button>
                            </Link>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default UserMenuItem;
