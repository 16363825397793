'use client';

import { ComponentProps } from 'react';
import clsx from 'clsx';

import { helpers } from 'common';
import styles from 'components/icon/styles/icon.module.scss';
import { Icons } from 'components/icon/types';

const { cn } = helpers;

export interface IProps extends ComponentProps<'div'> {
    name: Icons;
    strokeColor?: boolean;
    fillColor?: boolean;
}

const Icon = ({ name, className, strokeColor = true, fillColor, ...rest }: IProps) => {
    try {
        const Icon =
            // eslint-disable-next-line @typescript-eslint/no-var-requires
            require(`./icons/${name}.svg`).default;

        return (
            <div
                className={cn(
                    styles.icon,
                    {
                        [styles['stroke-color']]: strokeColor,
                        [styles['fill-color']]: fillColor,
                    },
                    className,
                )}
                {...rest}
            >
                <Icon />
            </div>
        );
    } catch (e) {
        return (
            <div
                className={clsx(className, styles.icon, {
                    [styles['stroke-color']]: strokeColor,
                    [styles['fill-color']]: fillColor,
                })}
                {...rest}
            />
        );
    }
};

export default Icon;
