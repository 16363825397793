'use client';

import { useMemo, useEffect } from 'react';

import { Icon } from 'components';
import { helpers } from 'common';

import 'animate.css';

interface IProps {
    size?: 'small' | 'medium' | 'large';
    title?: string;
    footer?: string | React.ReactNode;
    handleClose?: () => void;
    className?: string;
    titleClassName?: string;
    wrapperClassName?: string;
    customCloseButton?: React.ReactNode;
    closeIconClassName?: string;
    children: string | React.ReactNode;
}

const { cn } = helpers;

const Modal = ({ size, title, footer, handleClose, className, titleClassName, wrapperClassName, customCloseButton, closeIconClassName, children }: IProps) => {
    const modalSize = useMemo(() => {
        let result = '';

        switch (size) {
            case 'small':
                result = 'md:min-w-[300px]';
                break;
            case 'large':
                result = 'md:min-w-[800px]';
                break;
            default:
                result = 'md:min-w-[600px]';
                break;
        }

        return result;
    }, [size]);

    useEffect(() => {
        document.body.classList.add('modal-open');

        return () => {
            document.body.classList.remove('modal-open');
        };
    }, []);

    return (
        <div className="modal-open fixed left-0 right-0 top-0 bottom-0 bg-black-main bg-opacity-[0.5] overflow-auto z-[9999] flex items-center justify-center animate__animated animate__fadeIn animate__faster">
            <div className={cn('bg-white-main py-24 max-h-[90vh] relative rounded-[9px] max-w-[90vw] overflow-auto', modalSize, className || '')}>
                {customCloseButton
                    ? customCloseButton
                    : handleClose && <Icon name="x" className={cn('absolute cursor-pointer top-[12px] right-[12px]', closeIconClassName || '')} onClick={handleClose} />}
                <>
                    {title && (
                        <div className="px-24">
                            <p className={cn('text-title text-center', titleClassName || '')}>{title}</p>
                        </div>
                    )}
                    <div className={cn('p-24', wrapperClassName || '')}>{children}</div>
                    {footer && <div className="flex items-center justify-end px-24">{footer}</div>}
                </>
            </div>
        </div>
    );
};

export default Modal;
