import { helpers } from 'common';

import { Icon } from 'components';
const { generateRandomString, cn } = helpers;

interface IProps {
    id?: string;
    className?: string;
    acceptMultiple?: boolean;
    accept?: string;
    isCustomButton?: boolean;
    onChange: (_files: FileList) => void;
    disabled?: boolean;
    children: string | React.ReactNode;
    setTouched?: (_value: boolean) => void;
}

const Upload = ({ id, className, acceptMultiple, accept, isCustomButton, onChange, disabled, children, setTouched }: IProps) => {
    const elementId = id || generateRandomString(16);
    let isFileDialogOpened = false;
    const input = document.getElementById(elementId);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const allFiles: FileList | null = e.target.files;

        if (allFiles && allFiles.length > 0 && onChange) {
            const files: FileList | File[] = acceptMultiple ? allFiles : [allFiles[0]];
            onChange(files as FileList);
        }

        e.target.value = '';
    };

    const handleButtonClick = () => {
        const element = document.getElementById(elementId);

        if (element) {
            element.click();
        }
    };

    input?.addEventListener('click', function (_e) {
        isFileDialogOpened = true;
    });

    input?.addEventListener('blur', function (_e) {
        isFileDialogOpened = true;
    });

    window.addEventListener('focus', function (_e) {
        if (isFileDialogOpened) {
            if (setTouched) setTouched(true);
            isFileDialogOpened = false;
        }
    });

    return (
        <button
            className={isCustomButton ? className || '' : cn('w-auto, px-24 py-12 border rounded-[9px] border-dashed border-green-100 flex items-center', className)}
            onClick={handleButtonClick}
            disabled={disabled}
            type="button"
        >
            <input name={elementId} id={elementId} onChange={handleInputChange} className="hidden w-full h-full" type="file" multiple={acceptMultiple || false} accept={accept || ''} />
            {!isCustomButton && <Icon name="image-plus" className="mr-8" />}
            {typeof children === 'string' ? <p className="text-input text-green-100">{children}</p> : children}
        </button>
    );
};

export default Upload;
