'use client';

import { useState, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';

import { Select } from 'components';
import { queryKeys, helpers } from 'common';
import { regionAPI } from 'api';
import { IRegion, ICity } from 'api/region/types';

interface IProps {
    name: string;
    label?: string;
    placeholder?: string;
    serchPlaceholder?: string;
    className?: string;
    wrapperClassName?: string;
    listWrapperClassName?: string;
    value?: string | number | number[] | string[] | null;
    onChange?: (_name: string, _value?: string | number, _values?: number[] | string[]) => void;
    required?: boolean;
    error?: string;
    touched?: boolean;
    isMulti?: boolean;
}

const { getRegionsAPI } = regionAPI;
const { deepCopy } = helpers;

const CountriesSelect = ({ name, label, placeholder, serchPlaceholder, className, wrapperClassName, listWrapperClassName, value, onChange, required, error, touched, isMulti }: IProps) => {
    const { data: prefetchedRegions } = useQuery<IRegion[], Error, IRegion[], any>({
        queryKey: [queryKeys.REGIONS],
        queryFn: () => getRegionsAPI(),
        enabled: true,
        retry: false,
        cacheTime: Infinity,
        refetchOnWindowFocus: false,
    });

    const [filteredRegions, setFilterdRegions] = useState<IRegion[]>(prefetchedRegions || []);

    const handleSearchCities = (value?: string) => {
        const allRegions: IRegion[] = deepCopy(prefetchedRegions);

        if (value) {
            const result = [];

            for (let i = 0; i < allRegions.length; i++) {
                const cities = (allRegions[i].cities || {}).filter((city: ICity) => {
                    return city.name.toLowerCase().indexOf(value.toLowerCase()) > -1;
                });

                if (cities.length > 0) {
                    allRegions[i].cities = cities;
                    result.push(allRegions[i]);
                }
            }

            setFilterdRegions(result || []);
            return;
        }

        setFilterdRegions(allRegions);
    };

    const regions = useMemo(() => {
        return (filteredRegions || []).map((item: IRegion) => {
            return {
                value: item.id,
                label: item.name,
                cities: item.cities,
            };
        });
    }, [filteredRegions]);

    return (
        <Select
            name={name}
            label={label}
            placeholder={placeholder}
            onChange={onChange}
            value={value}
            options={regions}
            fullWidth
            multiOptions="cities"
            withSearch
            handleSearch={handleSearchCities}
            serchPlaceholder={serchPlaceholder}
            className={className}
            wrapperClassName={wrapperClassName}
            listWrapperClassName={listWrapperClassName}
            required={required}
            error={error}
            touched={touched}
            isMulti={isMulti}
        />
    );
};

export default CountriesSelect;
