'use client';

import { Button } from 'components';
import { useTranslation } from 'i18n/client';
import { helpers } from 'common';

interface IProps {
    lang: string;
    title?: string;
    text: string;
    cancelButtonText?: string;
    confirmButtonText?: string;
    params?: object;
    disabled?: boolean;
    handleClose: () => void;
    handleConfirm: (_params?: object) => void;
    titleClassName?: string;
}

const { cn } = helpers;

const ConfirmModal = ({ lang, title, text, cancelButtonText, confirmButtonText, params, disabled, handleClose, handleConfirm, titleClassName }: IProps) => {
    const { t } = useTranslation(lang, 'common');

    return (
        <>
            <p className={cn('text-title mb-24 text-center', titleClassName)}>{title || t('CONFIRM')}</p>
            <p className="text-sm-title text-center">{text}</p>
            <div className="pt-32 flex justify-center">
                <Button variant="secondary" onClick={handleClose} className="min-w-[100px] mr-12" disabled={disabled}>
                    {cancelButtonText || t('NO')}
                </Button>
                <Button onClick={() => handleConfirm(params)} className="min-w-[100px]" disabled={disabled}>
                    {confirmButtonText || t('YES')}
                </Button>
            </div>
        </>
    );
};

export default ConfirmModal;
