'use client';

import { Button, Modal } from 'components';

interface IProps {
    isOpen: boolean;
    title?: string;
    message: string;
    buttonText?: string;
    handleButtonClick?: () => void;
    handleClose?: () => void;
    modalSize?: 'small' | 'medium' | 'large';
    modalClassName?: string;
    customContent?: React.ReactNode;
    buttonVariant?: 'success' | 'secondary' | 'secondary-outline' | 'danger' | 'success-outline' | 'transparent';
    hideButton?: boolean;
    wrapperClassName?: string;
}

const InfoModal = ({ isOpen, title, message, buttonText, handleButtonClick, handleClose, modalSize, modalClassName, customContent, buttonVariant, hideButton, wrapperClassName }: IProps) => {
    if (!isOpen) {
        return null;
    }

    const onButtonClick = () => {
        if (handleButtonClick && typeof handleButtonClick === 'function') {
            handleButtonClick();
        }

        if (handleClose) {
            handleClose();
        }
    };

    return (
        <Modal
            size={modalSize || 'small'}
            className={modalClassName || '!min-w-[400px] !max-w-[400px]'}
            title={title}
            titleClassName="text-[20px] text-black-main font-medium"
            handleClose={handleClose}
            wrapperClassName={wrapperClassName}
        >
            <p className="text-sm-title text-grey-300 mb-24 font-light text-center">{message}</p>
            {customContent && <div className="mt-8 mb-32">{customContent}</div>}
            {!hideButton && (
                <Button variant={buttonVariant || 'success'} onClick={onButtonClick} className="w-full">
                    {buttonText || 'OK'}
                </Button>
            )}
        </Modal>
    );
};

export default InfoModal;
