'use client';

import { Label } from 'components';
import { helpers } from 'common';

interface IProps {
    name: string;
    value?: string | number;
    onChange?: (_e: React.FormEvent<HTMLTextAreaElement>) => void;
    onBlur?: (_e: React.FormEvent<HTMLTextAreaElement>) => void;
    error?: string;
    label?: string;
    placeholder?: string;
    touched?: boolean;
    disabled?: boolean;
    wrapperProps?: object;
    className?: string;
    id?: string;
    wrapperClassName?: string;
    required?: boolean;
    autoComplete?: 'on' | 'off' | string;
}

const { cn } = helpers;

const TextArea = ({ name, value, onChange, onBlur, error, label, placeholder, touched, disabled, wrapperProps, className = '', id, wrapperClassName, required, autoComplete, ...rest }: IProps) => {
    return (
        <div className={cn('pb-32', wrapperClassName || '')} {...(wrapperProps || {})}>
            {label && (
                <div className={error && touched ? 'text-red-100' : 'text-grey-100'}>
                    <Label htmlFor={id || name}>{label}</Label>
                    {required && <span className="ml-2 font-light text-red-200">*</span>}
                </div>
            )}
            <div className="block relative">
                <textarea
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    disabled={disabled}
                    placeholder={placeholder}
                    id={id || name}
                    className={cn(
                        'text-input resize-none border p-16 min-h-[140px] rounded-[6px] border-grey-200 w-full my-8 outline-none text-black-100',
                        error && touched ? 'border-red-100' : 'border-grey-200',
                        className,
                    )}
                    autoComplete={autoComplete}
                    {...rest}
                />
            </div>
            {error && touched && (
                <div className="relative mb-8">
                    <p className="text-error text-red-100 absolute top-[-4px]">{error}</p>
                </div>
            )}
        </div>
    );
};

export default TextArea;
