'use client';

import { useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { toast } from 'react-hot-toast';

import CardClickImage from 'common/images/card-click.svg';
import { Button, Modal, ConfirmModal } from 'components';
import { useTranslation } from 'i18n/client';
import { routesConstants, constants } from 'common';
import { VERIFY_TYPES } from 'components/header/constants';
import { userAPI, productAPI } from 'api';
import { IProductDetails } from 'api/product/types';

interface IProps {
    lang: string;
    status?: number;
    enableOnlinePayment?: boolean;
    enableOnlineForProduct?: boolean;
    productUUID?: string;
    productDetails?: IProductDetails;
    refetchDetails?: () => void;
}

const { routes, prefixes } = routesConstants;
const { updateUserAPI } = userAPI;
const { updateProductAPI } = productAPI;

const EndablePaymentCard = ({ lang, status, enableOnlinePayment, enableOnlineForProduct, productUUID, productDetails, refetchDetails }: IProps) => {
    const { t } = useTranslation(lang, 'common');
    const [inProgress, setInProgress] = useState<boolean>(false);
    const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);

    const getTitle = () => {
        if (productUUID) {
            return enableOnlineForProduct ? t('PAYMENT_DISABEL_TITLE_ARTICLE') : t('ARTICLE_PAYMENT_ENABLE_TITLE');
        }

        return enableOnlinePayment ? t('PAYMENT_DISABEL_TITLE') : t('PAYMENT_ENABLED_TITLE');
    };

    const getButtonText = () => {
        if (productUUID) {
            return enableOnlineForProduct ? t('TURN_OFF_PAYMENT') : t('TURN_ON_PAYMENT');
        }

        return enableOnlinePayment ? t('TURN_OFF_PAYMENT') : t('TURN_ON_PAYMENT');
    };

    const getVerifiedText = () => {
        if (productUUID) {
            return enableOnlineForProduct ? t('PAYMENT_DISABLE_PRODUCT_VERIFIED_SUBTITLE') : t('PAYMENT_ENABLED_PRODUCT_VERIFIED_SUBTITLE');
        }

        return enableOnlinePayment ? t('PAYMENT_DISABLE_VERIFIED_SUBTITLE') : t('PAYMENT_ENABLED_VERIFIED_SUBTITLE');
    };

    const getConfirmModalText = () => {
        if (productUUID) {
            return enableOnlineForProduct ? t('PAYMENT_DISABLE_PRODUCT_VERIFIED_MODAL_TEXT') : t('PAYMENT_ENABLED_PRODUCT_VERIFIED_MODAL_TEXT');
        }

        return enableOnlinePayment ? t('PAYMENT_DISABLE_VERIFIED_MODAL_TEXT') : t('PAYMENT_ENABLED_VERIFIED_MODAL_TEXT');
    };

    const getConfirmModalTitle = () => {
        if (productUUID) {
            return enableOnlineForProduct ? t('PAYMENT_DISABLE_PRODUCT_VERIFIED_MODAL_TITLE') : t('PAYMENT_ENABLED_PRODUCT_VERIFIED_MODAL_TITLE');
        }

        return enableOnlinePayment ? t('PAYMENT_DISABLE_VERIFIED_MODAL_TITLE') : t('PAYMENT_ENABLED_VERIFIED_MODAL_TITLE');
    };

    const handleToggleOnlinePayment = async () => {
        setConfirmModalOpen(true);
    };

    const toggleOnlinePayment = async () => {
        setInProgress(true);

        try {
            if (productUUID) {
                const isEnaled = !enableOnlineForProduct;

                await updateProductAPI(productUUID, {
                    allowOnlinePayments: isEnaled,
                    weight: productDetails?.weight || null,
                    height: productDetails?.height || null,
                    width: productDetails?.width || null,
                    length: productDetails?.length || null,
                });

                toast.success(t(isEnaled ? 'ONLINE_PAYMENT_PRODUCT_ENABLED_SUCCESS' : 'ONLINE_PAYMENT_PRODUCT_DISABLED_SUCCESS', { name: productDetails?.name || '' }));
            } else {
                const isEnaled = !enableOnlinePayment;
                await updateUserAPI({ onlinePaymentsEnabled: isEnaled });
                toast.success(t(isEnaled ? 'ONLINE_PAYMENT_ENABLED_SUCCESS' : 'ONLINE_PAYMENT_DISABLED_SUCCESS'));
            }

            if (refetchDetails) {
                refetchDetails();
            }

            setInProgress(false);
            setConfirmModalOpen(false);
        } catch (error) {
            setInProgress(false);
            toast.error(t('DEFAULT_ERROR_MESSAGE'));
        }
    };

    return (
        <div className="p-8 md:p-16 flex flex-wrap md:flex-nowrap items-center justify-center bg-grey-800 rounded-[9px]">
            {confirmModalOpen && (
                <Modal size="small" className="min-w-[500px] max-w-[500px]" handleClose={() => setConfirmModalOpen(false)}>
                    <ConfirmModal
                        title={getConfirmModalTitle()}
                        lang={lang}
                        text={getConfirmModalText()}
                        handleClose={() => setConfirmModalOpen(false)}
                        handleConfirm={toggleOnlinePayment}
                        disabled={inProgress}
                        titleClassName="!text-[24px]"
                    />
                </Modal>
            )}
            <Image src={CardClickImage} alt="about-us-image" width={150} height={120} className="!relative w-[150px] h-[120px]" />
            <div className="ml-[0px] md:ml-24 w-full">
                <p className="text-[24px] font-normal text-black-100 mb-8 text-center md:text-left">{getTitle()}</p>
                {constants.ONLINE_PAYMENT_VERIFICATION_STATUSES.initiated.value === status && (
                    <p className="text-[14px] font-normal text-grey-300 mb-12 text-center md:text-left">{t('PAYMENT_ENABLED_SUBTITLE')}</p>
                )}
                {constants.ONLINE_PAYMENT_VERIFICATION_STATUSES.pending.value === status && (
                    <p className="text-[14px] font-normal text-grey-300 mb-12 text-center md:text-left">{t('PAYMENT_ENABLED_PENDING_SUBTITLE')}</p>
                )}
                {constants.ONLINE_PAYMENT_VERIFICATION_STATUSES.rejected.value === status && (
                    <p className="text-[14px] font-normal text-grey-300 mb-12 text-center md:text-left">{t('PAYMENT_ENABLED_REJECTED_SUBTITLE')}</p>
                )}
                {constants.ONLINE_PAYMENT_VERIFICATION_STATUSES.verified.value === status && (
                    <p className="text-[14px] font-normal text-grey-300 mb-12 text-center md:text-left">{getVerifiedText()}</p>
                )}
                {(constants.ONLINE_PAYMENT_VERIFICATION_STATUSES.initiated.value === status || constants.ONLINE_PAYMENT_VERIFICATION_STATUSES.rejected.value === status) && (
                    <Link href={`/${lang}${routes[prefixes.verify].path}/${VERIFY_TYPES.payment}`}>
                        <Button className="w-full md:w-[auto]">{t('PAYMENT_ENABLED_BUTTON')}</Button>
                    </Link>
                )}
                {constants.ONLINE_PAYMENT_VERIFICATION_STATUSES.verified.value === status && (
                    <Button className="w-full md:w-[auto]" onClick={handleToggleOnlinePayment} disabled={inProgress} showDisabled showLoader={inProgress}>
                        {getButtonText()}
                    </Button>
                )}
            </div>
        </div>
    );
};

export default EndablePaymentCard;
