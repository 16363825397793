'use client';

import ReactSwitch from 'react-switch';

import { Label } from 'components';
import { helpers } from 'common';

interface IProps {
    label?: string;
    value?: boolean;
    onChange: (_value: boolean) => void;
    wrapperClassName?: string;
}

const { cn } = helpers;

const Switch = ({ label, value, onChange, wrapperClassName }: IProps) => {
    return (
        <div className={cn('pb-32 h-full', wrapperClassName || '')}>
            <div className="h-full flex items-end">
                <Label className="flex items-center pb-16 w-full">
                    {label && <span className="mr-12">{label}</span>}
                    <ReactSwitch onChange={onChange} checked={value || false} className="ml-[auto]" />
                </Label>
            </div>
        </div>
    );
};

export default Switch;
