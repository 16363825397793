'use client';

import Image from 'next/image';

import { Avatar, RenderIf } from 'components';

import { helpers } from 'common';

interface IProps {
    imageUrl?: string;
    username: string;
    message?: string;
    isSelected?: boolean;
    unreadCount?: number;
}

const { cn, shortenText } = helpers;

const ChatItem = ({ imageUrl, username, message, isSelected, unreadCount }: IProps) => {
    return (
        <div
            className={cn('p-8 md:p-16 rounded-[9px] flex items-center', isSelected ? 'border border-green-100' : '')}
            style={{ background: !isSelected && unreadCount && unreadCount > 0 ? 'rgba(232, 245, 230, 0.40)' : '' }}
        >
            <div className="flex items-center">
                {imageUrl ? (
                    <Image src={imageUrl} width={40} height={40} className="rounded-[50%] w-[40px] h-[40px] object-cover mr-8" alt={`user-chat-image-${username}`} />
                ) : (
                    <div className="rounded-[50%] w-[40px] h-[40px] mr-8 flex items-center justify-center border border-grey-600">
                        <Avatar />
                    </div>
                )}
                <div>
                    <p className="mb-8">{username}</p>
                    {message && <p>{shortenText(message, 30)}</p>}
                </div>
            </div>
            <RenderIf show={unreadCount && unreadCount > 0 ? true : false}>
                <div className="ml-[auto] w-[10px] h-[10px] bg-green-100 rounded-[50%]"></div>
            </RenderIf>
        </div>
    );
};

export default ChatItem;
