'use client';

import Image from 'next/image';
import Link from 'next/link';

import { helpers } from 'common';
import { Icon } from 'components';
import { getCurrencyCode } from 'common/helpers';

interface IProductField {
    choice?: string;
    productCategoryFieldID: number;
    uuid?: string;
    productCategoryField?: {
        id?: number;
        optional?: boolean;
        icon?: string;
        translations?: {
            [key: string]: string;
        };
        featured?: boolean;
        iconURI?: string;
        unit?: string;
    };
    unit?: string;
}

interface IProps {
    imageUrl?: string;
    upperTitle?: string;
    title?: string;
    price?: number | string;
    currency?: string;
    isNew?: boolean;
    imagesCount?: number;
    newLabel?: string;
    className?: string;
    cardClassName?: string;
    bottomPartClassName?: string;
    nameClassName?: string;
    priceClassName?: string;
    nameMaxChars?: number;
    location?: string;
    withImage?: boolean;
    imageClassName?: string;
    path?: string;
    isPromoted?: boolean;
    titleLink?: string;
    fields?: IProductField[];
    id?: string;
    promotedOnlyImage?: boolean;
    isBanner?: boolean;
    showLocationInFields?: boolean;
    hasVideo?: boolean;
    isGift?: boolean;
    giftLabel?: string;
    showArticleShadow?: boolean;
}

const { cn, parseBoolean } = helpers;

const ArticleCard = ({
    imageUrl,
    upperTitle,
    title,
    price,
    currency,
    isNew,
    imagesCount,
    newLabel,
    className,
    cardClassName,
    bottomPartClassName,
    nameClassName,
    priceClassName,
    location,
    withImage,
    imageClassName,
    path,
    isPromoted,
    titleLink,
    fields,
    id,
    promotedOnlyImage,
    isBanner,
    showLocationInFields,
    hasVideo,
    isGift,
    giftLabel,
    showArticleShadow,
}: IProps) => {
    const getFieldValue = (choice: string) => {
        if (choice === 'true' || choice === 'false') {
            return parseBoolean(choice) ? 'Da' : 'Ne';
        }

        return choice;
    };

    return (
        <Link href={path || ''}>
            <div
                className={cn('rounded-[9px] h-full', className || '', isPromoted && !promotedOnlyImage ? 'border-[3px] border-yellow-100' : '')}
                style={isPromoted || !showArticleShadow ? {} : { boxShadow: 'rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px' }}
                id={id}
            >
                {withImage && (
                    <div
                        className={cn(
                            'h-[250px] rounded-[9px] mb-12 relative',
                            cardClassName || '',
                            isBanner ? 'border-[3px] border-green-100' : '',
                            isPromoted && promotedOnlyImage ? 'border-[3px] border-yellow-100' : '',
                        )}
                    >
                        <Image src={imageUrl || ''} alt="product-image" objectFit="cover" fill loading="lazy" className={cn('rounded-[8px] !relative z-0 !rounded-b-[0px]', imageClassName || '')} />
                        {hasVideo && (
                            <div className="absolute left-[12px] top-[12px] bg-black-700 p-8 rounded-[50%] flex items-center">
                                <Icon name="video" className="white-icon" />
                            </div>
                        )}
                        {imagesCount && (
                            <div className="absolute left-[12px] bottom-[12px] bg-black-700 p-8 rounded-[8px] flex items-center">
                                <Icon name="image" className="w-[14px] h-[12px]" />
                                <p className="ml-4 text-xsm-title text-white-main font-bold">{imagesCount}</p>
                            </div>
                        )}
                    </div>
                )}
                {!withImage && (
                    <div
                        className={cn(
                            'h-[250px] rounded-[8px] bg-cover bg-center mb-12 relative',
                            cardClassName || '',
                            isPromoted && promotedOnlyImage ? 'border-[3px] border-yellow-100' : '',
                            isBanner ? 'border-[3px] border-green-100' : '',
                        )}
                        style={{ backgroundImage: `url(${imageUrl})` }}
                    >
                        {hasVideo && (
                            <div className="absolute left-[12px] top-[12px] bg-black-700 p-8 rounded-[50%] flex items-center">
                                <Icon name="video" className="white-icon" />
                            </div>
                        )}
                        {imagesCount && (
                            <div className="absolute left-[12px] bottom-[12px] bg-black-700 p-8 rounded-[8px] flex items-center">
                                <Icon name="image" className="w-[14px] h-[12px]" />
                                <p className="ml-4 text-xxxsm-title text-white-main font-bold">{imagesCount}</p>
                            </div>
                        )}
                    </div>
                )}
                <div className={bottomPartClassName || ''}>
                    {title && (
                        <>
                            {upperTitle && <p className="text-sm-title-light text-black-500 mb-8">{upperTitle}</p>}
                            <p className={cn('text-sm-title-light text-black-500 mb-8 block md:hidden', nameClassName || '')}>{title}</p>
                            {titleLink ? (
                                <Link href={titleLink} target="_blank" className="h-[40px] overflow-hidden whitespace-nowrap text-ellipsis text-sm-title-light text-black-500 mb-8 hidden md:block">
                                    {title}
                                </Link>
                            ) : (
                                <p className={cn('text-sm-title-light text-black-500 mb-8 hidden md:block', nameClassName || '')}>{title}</p>
                            )}
                        </>
                    )}

                    <div className="flex items-center flex-wrap mb-8">
                        {fields && fields.length > 0 && (
                            <>
                                {fields.map((field: IProductField, index: number) => {
                                    if (field.choice) {
                                        return (
                                            <div className="flex items-center mr-8" key={`item-product-field-${id || ''}-${index}`}>
                                                {field?.productCategoryField?.iconURI && (
                                                    <Image
                                                        src={field?.productCategoryField?.iconURI}
                                                        alt={`item-product-field-${id || ''}-${index}`}
                                                        width={13}
                                                        height={13}
                                                        className="w-[13px] h-[13px]"
                                                    />
                                                )}
                                                <div className="flex items-center">
                                                    <p className={cn('text-grey-2200 font-light text-[14px]', field?.productCategoryField?.iconURI ? 'ml-4' : '')}>
                                                        {getFieldValue(field?.choice || '')}
                                                    </p>
                                                    {(field?.productCategoryField?.unit || field.unit) && (
                                                        <p className="text-grey-2200 font-light text-[14px] ml-4">{field.unit || field?.productCategoryField?.unit}</p>
                                                    )}
                                                </div>
                                            </div>
                                        );
                                    }

                                    return null;
                                })}
                            </>
                        )}
                        {showLocationInFields && location && (
                            <div className="flex items-center mr-8">
                                <Icon name="map-pin" className="w-[13px] h-[13px] grey-icon" />
                                <div className="flex items-center">
                                    <p className="text-grey-2200 font-light text-[14px] ml-4">{location}</p>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="flex md:flex items-center">
                        {price && <p className={cn('font-semibold text-[20px] text-black-600 mr-12', priceClassName || '')}>{`${price} ${getCurrencyCode(currency ?? '')}`}</p>}
                        {isNew && (
                            <div className="flex items-center">
                                <Icon name="tag" className="w-[14px] h-[14px] mt-[2px]" />
                                {newLabel && <p className="text-xxsm-title text-red-200 ml-[2px] mt-[2px]">{newLabel}</p>}
                            </div>
                        )}
                        {isGift && (
                            <div className="flex items-center ml-8">
                                <Icon name="gift" className="w-[14px] h-[14px] mt-[2px] red-icon-second" />
                                {giftLabel && <p className="text-xxsm-title text-red-200 ml-[2px] mt-[2px]">{giftLabel}</p>}
                            </div>
                        )}
                    </div>
                    {location && !showLocationInFields && (
                        <div className="flex items-center mt-16">
                            <Icon name="map-pin" className="h-[20px]" /> <p className="ml-4 text-xsm-title font-normal text-black-500">{location}</p>
                        </div>
                    )}
                </div>
            </div>
        </Link>
    );
};

export default ArticleCard;
