'use client';
import { useEffect } from 'react';
import TagManager from 'react-gtm-module';

import { config } from 'core';

const Analytics = () => {
    useEffect(() => {
        if (config.GTM_ID) {
            TagManager.initialize({ gtmId: config.GTM_ID });
        }
    }, []);

    return <></>;
};

export default Analytics;
