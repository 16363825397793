'use client';

import Link from 'next/link';
import { useRouter } from 'next/navigation';

import { Button, Logo, RenderIf } from 'components';
import { routesConstants } from 'common';
import { removeSession } from 'core';
import { authAPI } from 'api';

interface IProps {
    linkText?: string;
    linkPath?: string;
    actionButtonText?: string;
    handleActionButtonClick?: () => void;
    actionButtonPath?: string;
    logoutText?: string;
    session?: {
        accessToken?: string;
        refreshToken?: string;
    };
    lang: string;
}

const { logoutAPI } = authAPI;
const { routes, prefixes } = routesConstants;

const OnboardingHeader = ({ linkText, linkPath, actionButtonText, handleActionButtonClick, actionButtonPath, logoutText, session, lang }: IProps) => {
    const router = useRouter();
    const isUserLoggedIn = !!(session && session.accessToken);

    const handleLogout = async () => {
        if (session && session.refreshToken) {
            await logoutAPI(session.refreshToken);
            removeSession();
        }

        router.refresh();
        router.replace(routes[prefixes.login].path);
    };

    return (
        <div className="flex items-center w-full">
            <Logo lang={lang} />
            <RenderIf show={!isUserLoggedIn}>
                <div className="flex items-center ml-[auto]">
                    {linkPath && linkText && (
                        <Link className="text-input hidden md:block text-black-main" href={linkPath}>
                            {linkText}
                        </Link>
                    )}
                    {actionButtonText &&
                        (handleActionButtonClick || actionButtonPath) &&
                        (handleActionButtonClick ? (
                            <Button variant="secondary-outline" className="ml-40 md:min-w-[240px]" onClick={handleActionButtonClick}>
                                {actionButtonText}
                            </Button>
                        ) : (
                            <Link href={actionButtonPath || ''}>
                                <Button variant="secondary-outline" className="ml-40 md:min-w-[240px]">
                                    {actionButtonText}
                                </Button>
                            </Link>
                        ))}
                </div>
            </RenderIf>
            <RenderIf show={isUserLoggedIn}>
                <p className="text-sm-title font-medium text-green-100 cursor-pointer ml-[auto]" onClick={handleLogout}>
                    {logoutText || ''}
                </p>
            </RenderIf>
        </div>
    );
};

export default OnboardingHeader;
