'use client';

import { useMemo } from 'react';

import { helpers } from 'common';
import { Label } from 'components';

interface IProps {
    name: string;
    value?: number | null;
    onChange?: (_name: string, _value: number | null) => void;
    label?: string;
    error?: string;
    touched?: boolean;
    required?: boolean;
    className?: string;
    wrapperClassName?: string;
    maxRating?: number;
}

const { cn } = helpers;

const Rating = ({ name, value, onChange, label, error, touched, required, className, wrapperClassName, maxRating }: IProps) => {
    const ratingItems = useMemo(() => {
        const itemsNumber = maxRating || 5;
        const items = [];

        const handleChange = (selectedValue: number | null) => {
            if (typeof onChange === 'function') {
                onChange(name, selectedValue);
            }
        };

        for (let i = 1; i <= itemsNumber; i++) {
            items.push(
                <div
                    onClick={() => handleChange(i)}
                    className={cn(
                        'w-[55px] h-[55px] rounded-[9px] flex items-center justify-center cursor-pointer text-[24px] mr-16',
                        i === value ? 'bg-green-100' : 'bg-green-300 border border-green-100',
                        className || '',
                    )}
                >
                    <p className={cn('font-light', i === value ? 'text-white-main' : 'text-green-100')}>{i}</p>
                </div>,
            );
        }

        return items;
    }, [maxRating, className, name, onChange, value]);

    return (
        <div className={cn('pb-32', wrapperClassName || '')}>
            {label && (
                <div className={error && touched ? 'text-red-100' : 'text-grey-100'}>
                    <Label htmlFor={name}>{label}</Label>
                    {required && <span className="ml-2 font-light text-red-200">*</span>}
                </div>
            )}
            <div className="flex mt-16">{ratingItems}</div>
            {error && touched && (
                <div className="relative mt-4">
                    <p className="text-error text-red-100 absolute top-[0px]">{error}</p>
                </div>
            )}
        </div>
    );
};

export default Rating;
