'use client';

import { Icon } from 'components';

interface IProps {
    className?: string;
    children: string | React.ReactNode;
}

const Info = ({ className, children }: IProps) => (
    <div className={className || ''}>
        <Icon name="info" className="mb-32" />
        {typeof children === 'string' ? <p className="text-sm-title-light text-grey-300">{children}</p> : children}
    </div>
);

export default Info;
