'use client';

import { useMemo } from 'react';
import { format } from 'date-fns';

import { INotification } from 'api/user/types';
import { helpers, constants, _ } from 'common';
import { Icon } from 'components';

interface IProps {
    lang: string;
    notification: INotification;
    t: (_key: string) => string;
    onClick: (_notification: INotification) => void;
}

interface INotificationItem {
    value: number;
    label: string;
    description?: string;
}

const { cn, divideTwoNumbers } = helpers;

const NotificationItem = ({ lang, notification, t, onClick }: IProps) => {
    const notifications: INotificationItem[] = useMemo(() => {
        return _.values(constants.NOTIFICATION_TYPES);
    }, []);

    const notificationItem: INotificationItem | null = useMemo(() => {
        return notifications.find((item: INotificationItem) => item.value === notification?.notificationTypeID) || null;
    }, [notification, notifications]);

    const getNotificationTitle = (): string => {
        const { notificationType, placeholderValues } = notification;
        const translation = notificationType?.translations;
        let placeholder: string = translation?.[lang as keyof typeof translation] || '';

        if (!placeholderValues) return placeholder;

        placeholder = Object.entries(placeholderValues).reduce((acc, [key, value]) => {
            let replacement: string | number = value;
            if (key.toLowerCase().includes('price')) {
                replacement = divideTwoNumbers(Number(value), 100, true);
                acc = acc.replace('BAM', 'KM');
            }
            return acc.replace(`[${key}]`, replacement ? replacement.toString() : '');
        }, placeholder);

        return placeholder;
    };

    return (
        <div className={cn('p-16 cursor-pointer mb-16', notification.viewed ? 'bg-white-main' : 'bg-green-400')} onClick={() => onClick(notification)}>
            <div className="flex items-center ">
                <div>
                    <div className="!w-[48px] !h-[48px] rounded-[50%] bg-white-main flex items-center justify-center">
                        <Icon name="bell" className="green-icon !w-[30px] !h-[30px]" />
                    </div>
                </div>
                <div className="ml-8">
                    {notificationItem && <p className="text-[18px] text-black-1100 font-medium mb-4">{t(notificationItem.label)}</p>}
                    <p className="text-sm-title text-grey-2300 font-light">{getNotificationTitle()}</p>
                </div>
            </div>
            <div className="flex mt-8">{notification?.createdAt && <p className="text-xsm-title text-grey-500 ml-[auto]">{format(new Date(notification.createdAt), 'dd.MM.yyyy HH:mm')}</p>}</div>
        </div>
    );
};

export default NotificationItem;
