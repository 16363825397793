'use client';

import { useState, useEffect } from 'react';

import { Icon, Input } from 'components';
import { helpers } from 'common';

interface IProps {
    onChange: (_value?: string) => void;
    debounce?: number;
    placeholder?: string;
    className?: string;
    inputClassName?: string;
    wrapperClassName?: string;
    passedValue?: string;
}

const { cn } = helpers;

const SearchInput = ({ onChange, placeholder, className, inputClassName, wrapperClassName, passedValue }: IProps) => {
    const [value, setValue] = useState<string>(passedValue || '');
    const handleChange = (e: any) => {
        const inputValue = e?.target?.value;

        setValue(inputValue || '');
        onChange(inputValue);
    };

    useEffect(() => {
        setValue(passedValue || '');
    }, [passedValue]);

    return (
        <div className={cn('relative', className || '')}>
            <Input name="search" value={value} onChange={handleChange} placeholder={placeholder} className={cn('pr-32', inputClassName || '')} wrapperClassName={wrapperClassName} />
            <div className="absolute right-0 top-[25px]">
                <Icon name="search" />
            </div>
        </div>
    );
};

export default SearchInput;
