'use client';

import { useMemo } from 'react';

import { Label } from 'components';
import { ISelectOption } from 'common/types';
import { helpers } from 'common';

interface IProps {
    name: string;
    options: ISelectOption[];
    onChange: (_name: string, _value?: string | number | null) => void;
    value?: string | number;
    label?: string;
    required?: boolean;
    error?: string;
    touched?: string;
    className?: string;
    tabClassName?: string;
    clearValue?: boolean;
}

const { cn } = helpers;

const SeparateTabs = ({ name, options, onChange, value, label, required, error, touched, className, tabClassName, clearValue }: IProps) => {
    const tabs = useMemo(() => {
        const handleChange = (selected: string | number) => {
            if (value === selected) {
                if (clearValue) {
                    onChange(name, null);
                }

                return;
            }

            onChange(name, selected);
        };

        return options.map((item: ISelectOption) => (
            <div
                key={`separate-tab-item-${item.value}`}
                className={cn(
                    'p-16 text-[14px] w-full font-light flex items-center justify-center cursor-pointer rounded-[6px]',
                    value === item.value ? 'font-normal bg-white-main text-green-100 border border-green-100' : 'bg-green-400 text-grey-1100',
                    tabClassName,
                )}
                onClick={() => handleChange(item.value)}
            >
                {item.label}
            </div>
        ));
    }, [options, value, name, onChange, tabClassName]);

    return (
        <div className="mt-24">
            {label && (
                <div className={error && touched ? 'text-red-100' : 'text-grey-100'}>
                    <Label>{label}</Label>
                    {required && <span className="ml-2 font-light text-red-200">*</span>}
                </div>
            )}
            <div className={cn('grid grid-cols-2 gap-12 mt-12', className || '')}>{tabs}</div>
        </div>
    );
};

export default SeparateTabs;
