'use client';

import { useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';

import { Avatar, Button, Icon, Modal, UserPhoneNumber } from 'components';
import { helpers } from 'common';
import { useTranslation } from 'i18n/client';

interface IProps {
    lang: string;
    imageUrl?: string;
    username: string;
    backText?: string;
    backPath?: string;
    userDetailsPath: string;
    viberButtonText?: string;
    callButtonText?: string;
    userPhoneNumber?: string;
    isDeleted?: boolean;
}

const { shortenText } = helpers;

const ChatHeder = ({ lang, imageUrl, username, backText, backPath, userDetailsPath, viberButtonText, callButtonText, userPhoneNumber, isDeleted }: IProps) => {
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const { t } = useTranslation(lang, 'common');

    const openViberChat = () => {
        const formattedPhoneNumber = (userPhoneNumber || '').replace(/\+/g, '%2B');
        const viberLink = `viber://chat?number=${formattedPhoneNumber}`;

        window.location.href = viberLink;
    };

    return (
        <div className="py-24 bg-transparent border-b border-b-grey-800 flex items-center justify-between md:justify-start">
            {modalOpen && (
                <Modal size="medium" className="w-full md:w-[500px] pt-[0px]" handleClose={() => setModalOpen(false)}>
                    <UserPhoneNumber lang={lang} phoneNumber={userPhoneNumber || ''} handleCloseModal={() => setModalOpen(false)} />
                </Modal>
            )}
            {backPath && (
                <Link href={backPath || ''}>
                    <div className="flex cursor-pointer md:hidden">
                        <Icon name="arrow-left" />
                        {backText && <p className="ml-8">{backText}</p>}
                    </div>
                </Link>
            )}
            {isDeleted && (
                <div className="flex items-center">
                    {imageUrl ? (
                        <Image src={imageUrl} width={40} height={40} className="rounded-[50%] w-[40px] h-[40px] mr-8 object-cover" alt={`user-chat-image-${username}`} />
                    ) : (
                        <div className="rounded-[50%] w-[40px] h-[40px] mr-8 flex items-center justify-center border border-grey-100">
                            <Icon name="user" />
                        </div>
                    )}
                    <div>
                        <p className="text-black-main text-sm-title hidden md:block">{t('USER_DELETED')}</p>
                    </div>
                </div>
            )}
            {!isDeleted && (
                <Link href={userDetailsPath}>
                    <div className="flex items-center">
                        {imageUrl ? (
                            <Image src={imageUrl} width={40} height={40} className="rounded-[50%] w-[40px] h-[40px] mr-8 object-cover" alt={`user-chat-image-${username}`} />
                        ) : (
                            <div className="rounded-[50%] w-[40px] h-[40px] mr-8 flex items-center justify-center border border-grey-600">
                                <Avatar />
                            </div>
                        )}
                        <div>
                            <p className="text-black-main text-sm-title hidden md:block">{username}</p>
                            <p className="text-black-main text-sm-title block md:hidden">{shortenText(username, 14)}</p>
                        </div>
                    </div>
                </Link>
            )}
            {userPhoneNumber && (
                <div className="flex items-center ml-[initial] md:ml-[auto]">
                    {viberButtonText && (
                        <>
                            <Button variant="transparent" className="items-center hidden md:flex !bg-purple-100 !text-purple-200 !max-h-[36px] !text-[14px] !font-normal" onClick={openViberChat}>
                                <Icon name="viber" className="mr-[6px]" />
                                {viberButtonText}
                            </Button>
                            <Button variant="transparent" className="flex md:hidden items-center !max-h-[28px] !max-w-[28px] !p-[0px] !text-[14px] !font-normal !border-0" onClick={openViberChat}>
                                <Icon name="viber" />
                            </Button>
                        </>
                    )}
                    {callButtonText && (
                        <>
                            <Button variant="transparent" className="hidden md:flex items-center !max-h-[36px] !text-[14px] !font-normal !border-0" onClick={() => setModalOpen(true)}>
                                <Icon name="call" className="w-[30px] h-[30px]" />
                                {callButtonText}
                            </Button>
                            <Button
                                variant="transparent"
                                className="flex md:hidden items-center !max-h-[28px] !max-w-[28px] !p-[0px] !text-[14px] !font-normal !border-0"
                                onClick={() => setModalOpen(true)}
                            >
                                <Icon name="call" className="w-[30px] h-[30px]" />
                            </Button>
                        </>
                    )}
                </div>
            )}
        </div>
    );
};

export default ChatHeder;
