import { helpers } from 'common';

interface IProps {
    text: string;
    className?: string;
}

const { cn } = helpers;

const SmallEmptyState = ({ text, className }: IProps) => (
    <div className={cn('p-12 rounded-[9px] bg-grey-600', className || '')}>
        <p className="text-sm-title text-grey-300 leading-[32px]">{text}</p>
    </div>
);

export default SmallEmptyState;
